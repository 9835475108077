import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { FaRedo, FaUpload, FaTrash } from 'react-icons/fa'; // Import the FontAwesome icons
import config from './config'; // Import your config

function Younger() {
    const [isCameraOpen, setIsCameraOpen] = useState(true); // Camera is open by default
    const [uploadedImagePath, setUploadedImagePath] = useState(''); // State to store the image URL
    const [responseData, setResponseData] = useState(null); // State to store the AI response data
    const [cameraError, setCameraError] = useState(false); // State to track camera errors
    const [loading, setLoading] = useState(false); // State to track loading status
    const [noClothingDetected, setNoClothingDetected] = useState(false); // State to track if no clothing was detected
    const [videoConstraints, setVideoConstraints] = useState({ facingMode: 'user' }); // Default to front camera
    const [pastedImage, setPastedImage] = useState(null); // State to store pasted image
    const [modalVisible, setModalVisible] = useState(false); // State to control modal visibility
    const [deletedOrderId, setDeletedOrderId] = useState(null); // State to store the deleted order ID
    const webcamRef = useRef(null);
    const fileInputRef = useRef(null); // Ref for file input

    useEffect(() => {
        setUploadedImagePath('');
        setNoClothingDetected(false);

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            setVideoConstraints({ facingMode: { exact: 'environment' } });
        } else {
            setVideoConstraints({ facingMode: 'user' });
        }
    }, []);

    const clearAllDataExceptImage = () => {
        setResponseData(null);
        setNoClothingDetected(false);
        setIsCameraOpen(false); // Keep the camera off if an image is uploaded
        setModalVisible(false);
        setDeletedOrderId(null);
        setCameraError(false);
    };

    const handleTakeSnapshot = async () => {
        clearAllDataExceptImage(); // Clear all data except the image

        if (webcamRef.current) {
            const image = webcamRef.current.getScreenshot();
            if (image) {
                setPastedImage(image); // Store the snapshot as the pasted image
                setIsCameraOpen(false); // Hide the camera preview
                await uploadFile(image); // Upload the snapshot directly after capture
            } else {
                console.error('Failed to capture the screenshot from the webcam.');
            }
        }
    };

    const handleUploadClick = async () => {
        clearAllDataExceptImage(); // Clear all data except the image

        if (pastedImage) {
            await uploadFile(pastedImage);
        } else if (isCameraOpen && webcamRef.current) {
            const image = webcamRef.current.getScreenshot();
            if (image) {
                setPastedImage(image); // Store the snapshot as the pasted image
                setIsCameraOpen(false); // Hide the camera preview
                await uploadFile(image); // Upload the snapshot
            }
        }
    };

    const handleStartOver = () => {
        // Stop all video tracks to release the camera
        if (webcamRef.current && webcamRef.current.stream) {
            webcamRef.current.stream.getTracks().forEach(track => track.stop());
        }

        // Reload the page to start fresh
        window.location.reload();
    };

    const handleFileChange = async (event) => {
        clearAllDataExceptImage(); // Clear all data except the image

        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPastedImage(reader.result);
                setIsCameraOpen(false); // Hide the camera preview
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePaste = async (event) => {
        clearAllDataExceptImage(); // Clear all data except the image

        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPastedImage(reader.result);
                    setIsCameraOpen(false); // Hide the camera preview
                };
                reader.readAsDataURL(blob);
            }
        }
    };

    const handleKeyPress = (event) => {
        event.preventDefault(); // Prevent any keypresses (text input) in the input field
    };

    const uploadFile = async (image) => {
        const filename = new Date().toISOString() + '.jpg';
        const blob = await fetch(image).then(res => res.blob());

        let formData = new FormData();
        formData.append('file', blob, filename); // 使用表單數據鍵 'file'

        setLoading(true);

        try {
            const response = await fetch(config.media2_younger_upload, { // 使用配置中的 URL
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('檔案上傳失敗');
            }

            const data = await response.json();
            console.log('檔案上傳成功:', data);
            setUploadedImagePath(data.imgUrl); // 使用響應中的 'imgUrl'

            // 檢查服務器是否返回了縮略圖 URL
            const thumbnailUrl = data.thumbnailUrl || '';  // 如果不存在則使用空字符串

            // 將圖像 URL 和縮略圖 URL 都傳遞給 AI 處理
            const aiResponse = await postImageAI(data.imgUrl, thumbnailUrl);
            setResponseData(aiResponse);
        } catch (error) {
            console.error('檔案上傳失敗:', error);
            alert('檔案上傳失敗: ' + error.message);
        } finally {
            setLoading(false);
        }
    };


    const postImageAI = async (imageUrl, thumbnailUrl) => {
        try {
            const response = await axios.post(config.younger01, {
                command: "image_ai",
                image_url: imageUrl,
                thumbnail_url: thumbnailUrl
            });
            console.log('AI Processing Success:', JSON.stringify(response.data, null, 2));
            if (response.data === null) {
                console.log('無衣物被偵測');
                setNoClothingDetected(true);
            }
            return response.data;
        } catch (error) {
            console.error('AI Processing Failed:', error);
            return null;
        }
    };

    const handleDeleteOrder = async (orderId) => {
        try {
            const response = await axios.post(config.younger01, {
                command: "delete_order",
                order_id: orderId
            });
            if (response.data.success === true) {
                setDeletedOrderId(orderId); // Store the deleted order ID
                setModalVisible(true); // Show the modal
            } else {
                alert('刪除訂單失敗');
            }
        } catch (error) {
            console.error('刪除訂單失敗:', error);
            alert('刪除訂單失敗: ' + error.message);
        }
    };

    const closeModal = () => {
        setModalVisible(false); // Close the modal
        setDeletedOrderId(null); // Reset the deleted order ID
        window.location.reload(); // Reload the page to start fresh
    };

    // const extractFilename = (info) => {
    //     const match = info.match(/'(.*?)'/);
    //     if (match && match.length > 1) {
    //         const filepath = match[1];
    //         return filepath.split('/').pop();
    //     }
    //     return null;
    // };

    return (
        <div className="container text-center mt-4 position-relative">
            <div className="position-relative">
                <h1>來到 Younger 頁面</h1>
                {!loading && (
                    <button
                        onClick={handleStartOver}
                        className="btn btn-primary position-fixed"
                        style={{
                            top: '10px',
                            right: '10px',
                            fontSize: '1.5rem',
                            padding: '0.5rem',
                            zIndex: 1000
                        }}
                    >
                        <FaRedo />
                    </button>
                )}
            </div>

            <p>點擊相機預覽拍攝照片，或上傳圖片。</p>

            {/* Image Upload and Paste Section */}
            <div className="row justify-content-center mt-4">
                <div className="col-md-6 col-sm-12 d-flex align-items-center">
                    <button
                        type="button"
                        className="btn btn-secondary me-2"
                        onClick={() => fileInputRef.current.click()}
                    >
                        +
                    </button>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="在此處貼上圖片或拖動圖片"
                        onPaste={handlePaste}
                        onKeyPress={handleKeyPress} // Disable text input while allowing image paste
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <button
                        type="button"
                        className="btn btn-primary ms-2 d-flex justify-content-center align-items-center"
                        onClick={handleUploadClick}
                        style={{ width: '100px' }}  // Set the width to 100px
                    >
                        <FaUpload className="me-1" /> 上傳
                    </button>
                </div>
            </div>

            {pastedImage && (
                <div className="d-flex justify-content-center">
                    <img src={pastedImage} alt="已貼上的照片" className="img-thumbnail" style={{ maxWidth: '350px', marginTop: '10px' }} />
                </div>
            )}

            {isCameraOpen && !uploadedImagePath && (
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6 col-sm-12">
                        <div className="position-relative">
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width="100%"
                                videoConstraints={videoConstraints}
                                onUserMediaError={() => setCameraError(true)}
                                onClick={handleTakeSnapshot}
                                style={{ cursor: 'pointer' }}
                            />
                            {cameraError && (
                                <div className="position-absolute top-50 start-50 translate-middle text-center text-danger fw-bold">
                                    相機預覽失敗
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {loading && (
                <div style={{
                    position: 'absolute',
                    top: '38%', // Position the loading spinner 30% from the top
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, 0)', // Adjust to center horizontally while keeping the 30% top margin
                    textAlign: 'center', // Center text within the container
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '1rem',
                    borderRadius: '10px',
                    zIndex: 1000, // Ensure the loading spinner is on top of other content
                }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-2">AI 分析中，請稍候...</p>
                </div>
            )}

            {noClothingDetected && !loading && (
                <div className="text-danger fw-bold mt-4">
                    無衣物被偵測或無法分析
                </div>
            )}

            {responseData && !loading && (
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6 col-sm-12">
                        <h3>AI 分析結果</h3>
                        <table className="table table-bordered mt-3">
                            <tbody>
                                <tr><th>訂單</th><td>{responseData.order_id}</td></tr>
                                <tr><th>時間</th><td>{responseData.timestamp}</td></tr>
                                <tr><th>價格</th><td>{responseData.price}</td></tr>
                                <tr><th>類型</th><td>{responseData.translation.type}</td></tr>
                                <tr><th>風格</th><td>{responseData.translation.style}</td></tr>
                                <tr><th>圖案</th><td>{responseData.translation.pattern}</td></tr>
                                <tr><th>顏色</th><td>{responseData.translation.colors.join(', ')}</td></tr>
                                <tr><th>材質</th><td>{responseData.translation.material}</td></tr>
                                <tr><th>用途</th><td>{responseData.translation.usage}</td></tr>
                                <tr><th>描述</th><td>{responseData.translation.description}</td></tr>
                                {responseData.thumbnail_url && (
                                    <tr>
                                        <th>縮略圖</th>
                                        <td>
                                            <img
                                                src={responseData.thumbnail_url}
                                                alt="縮略圖"
                                                style={{ maxWidth: '100px', maxHeight: '100px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {/* Centered Trash Button to Delete Order */}
                        <div className="d-flex justify-content-center">
                            <button
                                type="button"
                                className="btn btn-danger mt-3 d-flex align-items-center"
                                onClick={() => handleDeleteOrder(responseData.order_id)}
                            >
                                <FaTrash className="me-2" />
                                <span>刪除訂單</span>
                            </button>
                        </div>
                    </div>
                </div>

            )}

            {/* Modal for Deletion Confirmation */}
            {modalVisible && (
                <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">訂單刪除成功</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>訂單號碼 {deletedOrderId} 已成功刪除。</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={closeModal}>確定</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default Younger;
