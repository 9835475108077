const config = {
  // videoRoomId: "long-5c7e6300-4f7f-4a62-86f4-6b4978a1e1a3",
  videoRoomId: "community-9b6afccc-0e04-4a91-b044-af22a3aad212",
  videoChatBaseUrl: 'https://office7000.dtix.tw',
  dtix_upload: 'https://media.dtix.tw/uploadPic/community',
  linenotify: 'https://node1prod.dtix.tw/community',
  openAiApiKey: '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',
  securities: [
    {
      "title": "主任",
      "姓": "張",
      "名字": "守信",
      "手機": "+886912235475",
      "on_duty": true,
      "棟": "無",
      "樓": "無",
      "室": "警衛",
      "住址號碼": "123號"
    },  // "守信" means "trustworthy"
    {
      "title": "副主任",
      "姓": "李",
      "名字": "保安",
      "手機": "+886912235475",
      "on_duty": false,
      "棟": "無",
      "樓": "無",
      "室": "警衛",
      "住址號碼": "123號"
    }, // "保安" means "security"
    {
      "title": "保全員",
      "姓": "王",
      "名字": "護衛",
      "手機": "+886912235475",
      "on_duty": true,
      "棟": "無",
      "樓": "無",
      "室": "警衛",
      "住址號碼": "123號"
    }, // "護衛" means "guard"
  ],
  residents: [
    { "棟": "A", "樓": "10", "室": "之1", "住址號碼": "123號", "姓": "張", "名字": "志明", "手機": "+886912235475" },
    { "棟": "A", "樓": "11", "室": "之2", "住址號碼": "123號", "姓": "李", "名字": "小龍", "手機": "+886912235475" },
    { "棟": "A", "樓": "12", "室": "之3", "住址號碼": "123號", "姓": "王", "名字": "大明", "手機": "+886912235475" },
    { "棟": "B", "樓": "13", "室": "之4", "住址號碼": "123號", "姓": "陳", "名字": "玉珍", "手機": "+886912235475" },
    { "棟": "B", "樓": "14", "室": "之1", "住址號碼": "127號", "姓": "黃", "名字": "美麗", "手機": "+886912235475" },
    { "棟": "C", "樓": "15", "室": "之2", "住址號碼": "127號", "姓": "林", "名字": "志強", "手機": "+886912235475" },
    { "棟": "C", "樓": "16", "室": "之4", "住址號碼": "127號", "姓": "何", "名字": "俊傑", "手機": "+886912235475" },
    { "棟": "D", "樓": "17", "室": "之4", "住址號碼": "127號", "姓": "趙", "名字": "麗華", "手機": "+886912235475" },
    { "棟": "D", "樓": "18", "室": "之5", "住址號碼": "127號", "姓": "周", "名字": "文芳", "手機": "+886912235475" },
    { "棟": "E", "樓": "19", "室": "之2", "住址號碼": "130號", "姓": "楊", "名字": "子豪", "手機": "+886912235475" },
    { "棟": "E", "樓": "20", "室": "之3", "住址號碼": "131號", "姓": "吳", "名字": "思妤", "手機": "+886912235475" },
    { "棟": "F", "樓": "21", "室": "之1", "住址號碼": "132號", "姓": "徐", "名字": "志軒", "手機": "+886912235475" },
    { "棟": "F", "樓": "22", "室": "之4", "住址號碼": "133號", "姓": "方", "名字": "婉婷", "手機": "+886912235475" },
    { "棟": "G", "樓": "23", "室": "之2", "住址號碼": "134號", "姓": "賴", "名字": "冠宇", "手機": "+886912235475" },
    { "棟": "G", "樓": "24", "室": "之1", "住址號碼": "135號", "姓": "鄭", "名字": "依琳", "手機": "+886912235475" },
    { "棟": "H", "樓": "25", "室": "之3", "住址號碼": "136號", "姓": "周", "名字": "俊峰", "手機": "+886912235475" },
    { "棟": "H", "樓": "26", "室": "之1", "住址號碼": "137號", "姓": "洪", "名字": "詩涵", "手機": "+886912235475" },
    { "棟": "I", "樓": "27", "室": "之4", "住址號碼": "138號", "姓": "許", "名字": "承恩", "手機": "+886912235475" },
    { "棟": "I", "樓": "28", "室": "之2", "住址號碼": "139號", "姓": "葉", "名字": "彥霖", "手機": "+886912235475" },
    { "棟": "J", "樓": "29", "室": "之3", "住址號碼": "140號", "姓": "劉", "名字": "欣怡", "手機": "+886912235475" },
    { "棟": "J", "樓": "30", "室": "之1", "住址號碼": "141號", "姓": "蔡", "名字": "明哲", "手機": "+886912235475" },
    { "棟": "K", "樓": "31", "室": "之2", "住址號碼": "142號", "姓": "顏", "名字": "奕如", "手機": "+886912235475" },
    { "棟": "K", "樓": "32", "室": "之3", "住址號碼": "143號", "姓": "鍾", "名字": "信安", "手機": "+886912235475" },
    { "棟": "L", "樓": "33", "室": "之1", "住址號碼": "144號", "姓": "簡", "名字": "佳琪", "手機": "+886912235475" },
    { "棟": "L", "樓": "34", "室": "之4", "住址號碼": "145號", "姓": "白", "名字": "仲凱", "手機": "+886912235475" },
    { "棟": "M", "樓": "35", "室": "之2", "住址號碼": "146號", "姓": "潘", "名字": "芳瑜", "手機": "+886912235475" },
    { "棟": "M", "樓": "36", "室": "之1", "住址號碼": "147號", "姓": "蔣", "名字": "宇翔", "手機": "+886912235475" },
    { "棟": "N", "樓": "37", "室": "之3", "住址號碼": "148號", "姓": "龔", "名字": "詠恩", "手機": "+886912235475" },
    { "棟": "N", "樓": "38", "室": "之1", "住址號碼": "149號", "姓": "胡", "名字": "靖雯", "手機": "+886912235475" }  
  ]
};

export default config;
