import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import config from './config'; // Import config for videoRoomId
import './Call.css'; // Import custom CSS file

function Call() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const timestampParam = Number(params.get('code').substring(0, 13)); // Extract first 13 digits and convert to number

    const currentTime = new Date().getTime();
    const oneHourLater = timestampParam + 400000; // Adjust the time limit
    const diff = oneHourLater - currentTime;

    useEffect(() => {
        const idleTimer = setTimeout(() => {
            window.location.reload(); // Refresh the page after idle
        }, 400000);

        return () => clearTimeout(idleTimer);
    }, []);

    if (timestampParam && diff >= 0) {
        return (
            <div>
                <iframe
                    className="call-iframe"
                    src={`https://datavan.whereby.com/${config.videoRoomId}?bottomToolbar=off&lang=zh-hant&settingsButton=off&topToolbar=off&aec=on`}
                    allow="camera; microphone; fullscreen; speaker; display-capture; compute-pressure"
                    title="datavan"
                ></iframe>

                <div className="button-section">
                    <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>授權訪客通行證(15分鐘有效)</h3>
                    <div className="button-container">
                        <button className="btn btn-primary">
                            進大門到大廳
                        </button>
                        <button className="btn btn-secondary">
                            可到本棟一樓
                        </button>
                        <button className="btn btn-success">
                            可到我家樓層
                        </button>
                    </div>
                </div>
            </div>

        );
    } else {
        return <div>Expired or invalid link.</div>;
    }
}

export default Call;
