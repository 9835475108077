import React, { useEffect, useRef, useCallback, useState } from 'react';
import axios from 'axios';
import config from './config'; // Adjust the import path if needed

function Record({ onClose, onTranscription }) {
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const canvasRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const microphoneRef = useRef(null);
    const sourceRef = useRef(null);

    // State to track microphone access
    const [microphoneAccessError, setMicrophoneAccessError] = useState(false);

    const visualize = () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const canvasContext = canvas.getContext('2d');

        const renderFrame = () => {
            if (!analyserRef.current || !dataArrayRef.current) return;

            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

            canvasContext.fillStyle = 'rgb(200, 200, 200)';
            canvasContext.fillRect(0, 0, canvas.width, canvas.height);

            canvasContext.lineWidth = 2;
            canvasContext.strokeStyle = 'rgb(0, 0, 0)';
            canvasContext.beginPath();

            const sliceWidth = (canvas.width * 1.0) / analyserRef.current.frequencyBinCount;
            let x = 0;

            for (let i = 0; i < analyserRef.current.frequencyBinCount; i++) {
                const v = dataArrayRef.current[i] / 128.0;
                const y = (v * canvas.height) / 2;

                if (i === 0) {
                    canvasContext.moveTo(x, y);
                } else {
                    canvasContext.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasContext.lineTo(canvas.width, canvas.height / 2);
            canvasContext.stroke();

            requestAnimationFrame(renderFrame);
        };

        renderFrame();
    };

    const sendToOpenAI = useCallback(async (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.webm'); // Append file with correct extension
        formData.append('model', 'whisper-1'); // Model name for OpenAI STT

        try {
            const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
                headers: {
                    'Authorization': `Bearer ${config.openAiApiKey}`, // Use the API key from config.js
                    'Content-Type': 'multipart/form-data'
                }
            });

            const transcription = response.data.text;
            onTranscription(transcription); // Call the onTranscription function from props with the result
        } catch (error) {
            console.error('Error sending audio to OpenAI:', error);
        }
    }, [onTranscription]);





    useEffect(() => {
        // Create a new AudioContext if not already created
        try {
            if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
                audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                console.log('AudioContext created successfully.');
            }
        } catch (error) {
            console.error('Error creating AudioContext:', error);
            setMicrophoneAccessError(true);
            return;
        }

        // Ensure the AudioContext is in a running state
        if (audioContextRef.current.state === 'suspended') {
            audioContextRef.current.resume().then(() => {
                console.log('AudioContext resumed.');
            }).catch(err => {
                console.error('Error resuming AudioContext:', err);
                setMicrophoneAccessError(true);
                return;
            });
        }

        // Request microphone access, start recording
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                console.log('Microphone access granted.');
                microphoneRef.current = stream;

                // Ensure audioContextRef.current is initialized
                if (!audioContextRef.current) {
                    console.error('AudioContext is not initialized.');
                    setMicrophoneAccessError(true);
                    return;
                }

                try {
                    sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
                    console.log('MediaStreamSource created successfully.');
                    analyserRef.current = audioContextRef.current.createAnalyser();
                    analyserRef.current.fftSize = 2048;
                    sourceRef.current.connect(analyserRef.current);

                    dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
                    visualize();

                    try {
                        const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
                        mediaRecorderRef.current = mediaRecorder;
                        console.log('MediaRecorder initialized successfully.');
                        mediaRecorder.start();
                        console.log('MediaRecorder started.');

                        mediaRecorder.ondataavailable = (event) => {
                            console.log('MediaRecorder data available.');
                            audioChunks.current.push(event.data);
                        };

                        mediaRecorder.onstop = async () => {
                            console.log('MediaRecorder stopped.');
                            const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
                            audioChunks.current = [];

                            // Send audioBlob to OpenAI for STT processing
                            await sendToOpenAI(audioBlob);
                        };

                        mediaRecorder.onerror = (error) => {
                            console.error('MediaRecorder error:', error);
                        };
                    } catch (mediaRecorderError) {
                        console.error('Error starting MediaRecorder:', mediaRecorderError);
                        setMicrophoneAccessError(true);
                    }
                } catch (sourceError) {
                    console.error('Error creating media stream source:', sourceError);
                    setMicrophoneAccessError(true);
                }
            })
            .catch((error) => {
                console.error('Error accessing microphone:', error);
                setMicrophoneAccessError(true);
            });

        // Cleanup function to stop all media streams and reset recorder
        return () => {
            if (mediaRecorderRef.current) {
                console.log('Stopping MediaRecorder.');
                mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
                mediaRecorderRef.current = null;
            }
            if (microphoneRef.current) {
                console.log('Stopping microphone stream.');
                microphoneRef.current.getTracks().forEach(track => track.stop());
                microphoneRef.current = null;
            }
            if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
                console.log('Closing AudioContext.');
                audioContextRef.current.close().then(() => {
                    audioContextRef.current = null;
                });
            }
        };
    }, [sendToOpenAI]);





    const handleStopRecording = () => {
        onClose(); // Call the onClose function from props to close the modal
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        // Ensure all media streams are stopped
        if (microphoneRef.current) {
            microphoneRef.current.getTracks().forEach(track => track.stop());
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
            {/* Warning Dialog for Microphone Access */}
            {microphoneAccessError && (
                <div
                    style={{
                        position: 'fixed',
                        top: '20%',
                        left: '50%',
                        transform: 'translate(-50%, -20%)',
                        backgroundColor: '#ffdddd',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                        zIndex: 1001,
                        textAlign: 'center',
                    }}
                >
                    <p style={{ color: '#d8000c', fontSize: '1.2rem' }}>無法存取麥克風。請檢查您的瀏覽器權限設置。</p>
                    <button
                        onClick={onClose}
                        style={{
                            backgroundColor: '#d8000c',
                            color: '#fff',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            border: 'none',
                        }}
                    >
                        關閉
                    </button>
                </div>
            )}

            {/* Modal for waveform visualization and stop button */}
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px',
                    zIndex: 1000,
                }}
            >
                {/* Content area with white background */}
                <div
                    style={{
                        position: 'relative',
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        padding: '30px',
                        textAlign: 'center',
                        width: '80%',
                        maxWidth: '600px',
                    }}
                >


                    {/* List of example questions with larger font size */}
                    <ul
                        style={{
                            textAlign: 'center',
                            marginBottom: '20px',
                            listStyleType: 'none',
                            padding: 0,
                            fontFamily: 'Verdana, sans-serif',
                            fontSize: '1.5rem',
                        }}
                    >
                        {[
                            '我要找張先生,但是忘了名字',
                            '我要找管理部的同事,但是我忘了名字?',
                            '我要找 袁龍聖, 請幫我連繫'
                        ].map((text, index) => (
                            <li
                                key={index}
                                style={{
                                    padding: '16px 20px',
                                    marginBottom: '10px',
                                    backgroundColor: '#e6f4ff',
                                    color: '#0056b3',
                                    borderLeft: '6px solid #007bff',
                                    borderRadius: '6px',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s ease',
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor = '#d0e7ff')
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor = '#e6f4ff')
                                }
                            >
                                {text}
                            </li>
                        ))}
                    </ul>

                    {/* Canvas for the waveform */}
                    <canvas
                        ref={canvasRef}
                        width="300"
                        height="200"
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            marginBottom: '20px',
                        }}
                    />

                    <h3 style={{ marginBottom: '40px' }}>請說話...結束按下面停止鍵</h3>

                    {/* Center the stop button */}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '20px'
                        }}
                    >
                        <button
                            onClick={handleStopRecording}
                            style={{
                                width: '130px',
                                height: '130px',
                                fontSize: '4rem',
                                borderRadius: '50%',
                                backgroundColor: '#000',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            <div
                                style={{
                                    width: '50%',
                                    height: '50%',
                                    backgroundColor: 'white',
                                }}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Record;
