import React, { useState, useEffect } from 'react';
import mqtt from 'mqtt';
import './Mqtt.css'; // Assuming you have a CSS file for styles
import config from './config'; // Import the config object

const Mqtt = () => {
    const [message, setMessage] = useState(''); // To handle input for publishing
    const [receivedMessages, setReceivedMessages] = useState([]); // To store received messages
    const [client, setClient] = useState(null); // MQTT client

    const { brokerURL, username, password, topic } = config.MQTT; // Destructure MQTT config

    // Connect to the MQTT broker on component mount
    useEffect(() => {
        const mqttClient = mqtt.connect(brokerURL, {
            username: username,
            password: password,
            reconnectPeriod: 1000 // Reconnect every 1 second
        });

        mqttClient.on('connect', () => {
            console.log('Connected to MQTT broker');
            mqttClient.subscribe(topic, (err) => {
                if (!err) {
                    console.log('Subscribed to topic:', topic);
                }
            });
        });

        mqttClient.on('error', (err) => {
            console.error('Connection error:', err);
        });

        mqttClient.on('message', (topic, payload) => {
            const msg = payload.toString();
            setReceivedMessages((prevMessages) => [...prevMessages, msg]);
        });

        setClient(mqttClient);

        return () => {
            if (mqttClient) mqttClient.end(); // Clean up the connection on unmount
        };
    }, [brokerURL, username, password, topic]); // Added dependencies

    // Function to handle publishing messages
    const publishMessage = () => {
        if (client && message) {
            client.publish(topic, message);
            console.log('Published message:', message);
            setMessage(''); // Clear the input field after publishing
        }
    };

    return (
        <div className="container">
            <h2>MQTT WebSocket Client</h2>

            {/* Input for publishing */}
            <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type message to publish"
                className="input"
            />
            <button onClick={publishMessage} className="publish-button">
                Publish
            </button>

            {/* Received message display area */}
            <div className="message-box">
                {receivedMessages.length > 0 ? (
                    receivedMessages.map((msg, index) => (
                        <p key={index}>
                            <strong>Received:</strong> {msg}
                        </p>
                    ))
                ) : (
                    <p>No messages received yet...</p>
                )}
            </div>
        </div>
    );
};

export default Mqtt;
