import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { FaTshirt, FaSearch } from 'react-icons/fa'; // 導入圖標

function YoungerLayout({ children }) {
    const location = useLocation();

    const navBarStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: '#40C1AC', // 柔和的青色背景
        borderRadius: '10px',
        padding: '10px 0',
        marginBottom: '20px',
    };

    const buttonStyle = {
        textDecoration: 'none',
        padding: '10px 20px',
        borderRadius: '10px',
        color: '#fff',
        backgroundColor: '#40C1AC',
        fontSize: '1rem',
        fontWeight: 'bold',
        border: 'none',
        transition: 'background-color 0.3s ease',
    };

    const activeButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#ffffff',
        color: '#40C1AC',
    };

    const iconStyle = {
        marginRight: '10px',
    };

    return (
        <Container fluid>
            <div style={{ padding: '20px' }}>
                <div style={navBarStyle}>
                    <Link
                        to="/younger"
                        style={location.pathname === '/younger' ? activeButtonStyle : buttonStyle}
                    >
                        Younger
                    </Link>

                    <Link
                        to="/search"
                        style={location.pathname === '/search' ? activeButtonStyle : buttonStyle}
                    >
                        Search
                    </Link>                    
                    <Link
                        to="/clothing"
                        style={location.pathname === '/clothing' ? activeButtonStyle : buttonStyle}
                    >
                        <FaTshirt style={iconStyle} /> 衣服登錄
                    </Link>
                    <Link
                        to="/clothingsearch"
                        style={location.pathname === '/clothingsearch' ? activeButtonStyle : buttonStyle}
                    >
                        <FaSearch style={iconStyle} /> 衣服搜尋
                    </Link>
                </div>
                {children}
            </div>
        </Container>
    );
}

export default YoungerLayout;