import React, { useState, useEffect } from 'react';
import mqtt from 'mqtt';
import './Door.css'; // Combined CSS file for the layout

const GateElevator = () => {
    // State for controlling the gates and elevator
    const [isGateOpen, setIsGateOpen] = useState(false);
    const [isElevatorMoving, setIsElevatorMoving] = useState(false);
    const [ismain01Open, setIsmain01Open] = useState(false);
    const [mqttMessageElevator, setMqttMessageElevator] = useState(''); // For elevator MQTT message
    const [mqttMessagemain01, setMqttMessagemain01] = useState(''); // For lobby gate MQTT message
    const [mqttMessagelobby01, setMqttMessagelobby01] = useState(''); // For main gate MQTT message
    const [mqttMessagePage, setMqttMessagePage] = useState(''); // For page topic MQTT message
    const [isValidMessage, setIsValidMessage] = useState(true); // Track


    const [client, setClient] = useState(null); // MQTT client state

    // MQTT broker details
    const brokerURL = 'wss://ws.datavansolutions.com:443/mqtt';
    const username = 'datavan_front';
    const password = 'datavanpork123';

    // Lobby gate topics
    const main01SubscribeTopic = 'stat/tasmota_111111/RESULT';
    const main01PublishTopic = 'cmnd/tasmota_111111/POWER';

    // Main gate topics
    const lobby01SubscribeTopic = 'stat/tasmota_222222/RESULT';
    const lobby01PublishTopic = 'cmnd/tasmota_222222/POWER';

    // Elevator topics
    const elevatorSubscribeTopic = 'stat/tasmota_00E6A7/RESULT';
    const elevatorPublishTopic = 'cmnd/tasmota_00E6A7/POWER';


    const pageTopic = 'page/tasmota/cmd';

    // Connect to the MQTT broker on component mount
    useEffect(() => {
        const mqttClient = mqtt.connect(brokerURL, {
            username: username,
            password: password,
            reconnectPeriod: 1000 // Reconnect every 1 second
        });

        mqttClient.on('connect', () => {
            console.log('Connected to MQTT broker');
            // Subscribe to all topics
            mqttClient.subscribe(elevatorSubscribeTopic);
            mqttClient.subscribe(main01SubscribeTopic);
            mqttClient.subscribe(lobby01SubscribeTopic);
            mqttClient.subscribe(main01PublishTopic); // Subscribing to th
            mqttClient.subscribe(lobby01PublishTopic); // Subscribing to
            mqttClient.subscribe(pageTopic); // Subscribing to
        });

        mqttClient.on('error', (err) => {
            console.error('Connection error:', err);
        });

        mqttClient.on('message', (topic, message) => {
            const msg = message.toString();
            console.log(`Received message on topic ${topic}:`, msg);

            if (topic === pageTopic) {
                try {
                    const parsedMessage = JSON.parse(msg); // Parse the received message

                    // Display message for "visitor is scan to enter"
                    const visitorMessage = `訪客掃描進入`;

                    // Convert valid_timestamp to Traditional Chinese date format
                    const validTime = new Date(parsedMessage.valid_timestamp).toLocaleString('zh-TW', { timeZone: 'Asia/Taipei' });

                    // Set the validity of the message
                    setIsValidMessage(parsedMessage.valid);

                    // Set the page message in the format you want to display
                    const displayMessage = `${visitorMessage}, ${parsedMessage.valid ? '有效' : '無效'}，有效時間：${validTime}`;
                    setMqttMessagePage(displayMessage); // Set the received message for the page topic

                    // Clear the message after 5 seconds (5000 milliseconds)
                    setTimeout(() => {
                        setMqttMessagePage(''); // Clear the message
                    }, 5000); // Delay of 5 seconds

                    
                } catch (error) {
                    console.error('Failed to parse MQTT message:', error);
                }
            }




            // Handle Lobby Gate topic  special do for non controller
            if (topic === main01PublishTopic) {
                console.log(msg)
                if (msg.includes('ON')) {
                    setIsmain01Open(true);
                    setTimeout(() => {
                        mqttClient.publish(main01PublishTopic, 'OFF');
                        console.log(`Published: OFF to topic ${main01PublishTopic}`);
                        setIsmain01Open(false);
                    }, 3000); // 3-second delay
                }
            }


            // Handle Lobby Gate topic  special do for non controller
            if (topic === lobby01PublishTopic) {
                console.log(msg)

                if (msg.includes('ON')) {
                    setIsGateOpen(true);
                    setTimeout(() => {
                        mqttClient.publish(lobby01PublishTopic, 'OFF');
                        console.log(`Published: OFF to topic ${lobby01PublishTopic}`);
                        setIsGateOpen(false);
                    }, 3000); // 3-second delay
                }
            }


            // Handle Lobby Gate topic
            if (topic === main01SubscribeTopic) {
                console.log(msg)
                setMqttMessagemain01(msg); // Set the received message for the lobby gate
                handlemain01Message(msg);

                // If the message is "ON", publish "OFF" after 3 seconds
                if (msg.includes('ON')) {
                    setTimeout(() => {
                        mqttClient.publish(main01PublishTopic, 'OFF');
                        console.log(`Published: OFF to topic ${main01PublishTopic}`);
                    }, 3000); // 3-second delay
                }
            }

            // Handle Main Gate topic
            if (topic === lobby01SubscribeTopic) {
                setMqttMessagelobby01(msg); // Set the received message for the main gate
                handlelobby01Message(msg);

                // If the message is "ON", publish "OFF" after 3 seconds
                if (msg.includes('ON')) {
                    setTimeout(() => {
                        mqttClient.publish(lobby01PublishTopic, 'OFF');
                        console.log(`Published: OFF to topic ${lobby01PublishTopic}`);
                    }, 3000); // 3-second delay
                }
            }

            // Handle Elevator topic
            if (topic === elevatorSubscribeTopic) {
                setMqttMessageElevator(msg); // Set the received message for the elevator
                handleElevatorMessage(msg);

                // If the message is "ON", publish "OFF" after 3 seconds
                if (msg.includes('ON')) {
                    setTimeout(() => {
                        mqttClient.publish(elevatorPublishTopic, 'OFF');
                        console.log(`Published: OFF to topic ${elevatorPublishTopic}`);
                    }, 3000); // 3-second delay
                }
            }
        });

        setClient(mqttClient);

        return () => {
            if (mqttClient) mqttClient.end(); // Clean up the connection on unmount
        };
    }, [brokerURL, username, password, elevatorSubscribeTopic, main01SubscribeTopic, lobby01SubscribeTopic]);



    // Function to handle MQTT message for the lobby gate
    const handlemain01Message = (msg) => {
        try {
            const parsedMessage = JSON.parse(msg);
            if (parsedMessage.POWER === 'ON') {
                setIsmain01Open(true);
                setTimeout(() => {
                    setIsmain01Open(false);
                }, 2000); // Lobby gate closes after 2 seconds
            }
        } catch (error) {
            console.error('Failed to parse MQTT message', error);
        }
    };

    // Function to publish ON and then OFF for the lobby gate with a 2-second delay
    const togglemain01Power = () => {
        if (client) {
            client.publish(main01PublishTopic, 'ON');
            setIsmain01Open(true);
            setTimeout(() => {
                // client.publish(main01PublishTopic, 'OFF');
                setIsmain01Open(false);
            }, 2000);
        }
    };


    // Function to handle MQTT message for the elevator
    const handleElevatorMessage = (msg) => {
        try {
            const parsedMessage = JSON.parse(msg);
            if (parsedMessage.POWER === 'ON') {
                setIsElevatorMoving(true);
                setTimeout(() => {
                    setIsElevatorMoving(false);
                }, 3000); // Elevator stops after 3 seconds
            }
        } catch (error) {
            console.error('Failed to parse MQTT message', error);
        }
    };

    // // Function to handle MQTT message for the main gate
    const handlelobby01Message = (msg) => {
        try {
            const parsedMessage = JSON.parse(msg);
            if (parsedMessage.POWER === 'ON') {
                setIsGateOpen(true);
                setTimeout(() => {
                    setIsGateOpen(false);
                }, 2000); // Main gate closes after 2 seconds
            }
        } catch (error) {
            console.error('Failed to parse MQTT message', error);
        }
    };



    // Function to publish ON and then OFF for the elevator with a 3-second delay
    const toggleElevatorPower = () => {
        if (client) {
            client.publish(elevatorPublishTopic, 'ON');
            setIsElevatorMoving(true);
            setTimeout(() => {
                // client.publish(elevatorPublishTopic, 'OFF');
                setIsElevatorMoving(false);
            }, 3000);
        }
    };


    // Function to publish ON and then OFF for the main gate with a 2-second delay
    const togglelobby01Power = () => {
        if (client) {
            client.publish(lobby01PublishTopic, 'ON');
            setIsGateOpen(true);
            setTimeout(() => {
                client.publish(lobby01PublishTopic, 'OFF');
                setIsGateOpen(false);
            }, 2000);
        }
    };

    return (
        <div className="gate-elevator-container">
            <h1 className="title">鴻翊大觀社區大門、電梯門禁控制</h1>

            {/* Visitor Entry Section */}
            <div >
                <h2 className="section-title">訪客進入記錄</h2>
                <p className={`visitor-message ${!isValidMessage ? 'alert' : ''}`}>
                    {mqttMessagePage}
                </p> {/* Display the message from page topic */}
                <p className="topic-info">{pageTopic}</p> {/* Display the topic name */}
            </div>


            <div className="gate-elevator-wrapper">

                {/* 住戶大廳柵欄門區段 */}
                <div className="box lobby-gate-wrapper">
                    <h2 className="section-title">社區大門</h2>
                    <div className="barrier-gate">
                        <div className={`barrier-arm ${ismain01Open ? 'closed' : 'open'}`}></div>
                    </div>
                    <button
                        className={`toggle-button ${ismain01Open ? 'alert-button' : ''}`}
                        onClick={togglemain01Power}
                    >
                        {ismain01Open ? '放下柵欄' : '升起柵欄'}
                    </button>
                    {/* Display the received MQTT message for the lobby gate */}
                    <br />
                    <p>{mqttMessagemain01}</p>
                    <p style={{ color: 'grey' }}>{main01SubscribeTopic}</p>
                </div>

                {/* 大門區段 */}
                <div className="box gate-wrapper">
                    <h2 className="section-title">A棟大門</h2>
                    <div className="gate">
                        <div className={`gate-left ${isGateOpen ? 'open' : 'closed'}`}></div>
                        <div className={`gate-right ${isGateOpen ? 'open' : 'closed'}`}></div>
                    </div>
                    <button
                        className={`toggle-button ${isGateOpen ? 'alert-button' : ''}`}
                        onClick={togglelobby01Power}
                    >
                        {isGateOpen ? '關閉門' : '打開門'}
                    </button>
                    {/* Display the received MQTT message for the main gate */}
                    <br />
                    <p>{mqttMessagelobby01}</p>
                    <p style={{ color: 'grey' }}>{lobby01SubscribeTopic}</p>
                </div>

                {/* 住戶電梯控制區段 */}
                <div className="box elevator-wrapper">
                    <h2 className="section-title">A棟樓層住戶電梯</h2>
                    <div className="shaft">
                        <div className={`elevator ${isElevatorMoving ? 'moving' : ''}`}>
                            {/* Elevator movement */}
                        </div>
                    </div>
                    <button
                        className={`toggle-button ${isElevatorMoving ? 'alert-button' : ''}`}
                        onClick={toggleElevatorPower}
                    >
                        {isElevatorMoving ? '停止電梯' : '啟動電梯'}
                    </button>
                    {/* Display the received MQTT message for the elevator */}
                    <br />
                    <p>{mqttMessageElevator}</p>
                    <p style={{ color: 'grey' }}>{elevatorSubscribeTopic}</p>
                </div>
            </div>
        </div>
    );
};

export default GateElevator;
