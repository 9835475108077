import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config-community';

function Contacts({ onSecurityClick, onRoomClick }) { // Destructure the props here
    // States to manage selections
    const [selectedBuilding, setSelectedBuilding] = useState(null); // "棟"
    const [selectedFloor, setSelectedFloor] = useState(null); // "樓"
    const [residents] = useState(config.residents); // Original residents data

    // Group residents by "棟"
    const getBuildings = () => {
        const buildings = [...new Set(residents.map(resident => resident.棟))]; // Unique "棟"
        return buildings;
    };

    // Get floors based on selected "棟"
    const getFloors = (building) => {
        const floors = [...new Set(residents.filter(resident => resident.棟 === building).map(resident => resident.樓))]; // Unique "樓"
        return floors;
    };

    // Get rooms based on selected "棟" and "樓"
    const getRooms = (building, floor) => {
        const rooms = residents.filter(resident => resident.棟 === building && resident.樓 === floor);
        return rooms;
    };

    // Find the resident for the selected building to get the address number
    const residentForAddress = selectedBuilding ? residents.find(res => res.棟 === selectedBuilding) : null;
    const addressNumber = residentForAddress?.住址號碼 || 'N/A';

    // Style for buttons to make them smaller
    const largeButtonStyle = {
        fontSize: '1.2rem', // Reduced font size from 1.5rem
        padding: '10px 20px', // Reduced padding from 20px 40px
        minWidth: '150px', // Reduced minimum width from 200px
        margin: '5px', // Reduced margin from 10px
    };


    // Function to handle security button clicks
    const handleSecurityClick = (security) => {
        onSecurityClick(security); // Use prop passed from Help
    };

    // Function to handle room button clicks
    const handleRoomClick = (resident) => {
        onRoomClick(resident); // Use prop passed from Help
    };

    return (
        <div style={{ padding: '20px' }}>
            {/* Security Contacts Section */}
            <div style={{ marginBottom: '20px' }}>
                <h4>執班保全:</h4>
                {config.securities.map((security, index) => (
                    <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                        <button
                            className="btn m-2"
                            style={{
                                ...largeButtonStyle,
                                backgroundColor: security.on_duty ? '#6c757d' : 'transparent', // Secondary color for on-duty, transparent for off-duty
                                color: security.on_duty ? 'white' : '#6c757d', // White text for on-duty, muted text for off-duty
                                borderColor: security.on_duty ? '#5a6268' : '#ced4da', // Darker secondary for on-duty, light border for off-duty
                                boxShadow: security.on_duty ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none', // Subtle shadow for on-duty
                                cursor: security.on_duty ? 'pointer' : 'not-allowed', // Pointer cursor if clickable
                                opacity: security.on_duty ? 1 : 0.7, // Muted appearance for off-duty
                                transition: 'transform 0.2s',
                            }}
                            onClick={security.on_duty ? () => handleSecurityClick(security) : undefined}
                            disabled={!security.on_duty} // Disable the button if off-duty
                        >
                            {/* First line: Security name */}
                            <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                {security.姓}{security.名字}
                            </div>
                            {/* Second line: Title */}
                            <div style={{ fontSize: '1.2rem', opacity: 0.9 }}>
                                {security.title}
                            </div>
                        </button>

                        {/* On-duty badge */}
                        {security.on_duty && (
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '-10px',
                                    backgroundColor: '#ffc107', // Yellow badge
                                    color: '#212529',
                                    borderRadius: '50%',
                                    padding: '5px 10px',
                                    fontSize: '0.8rem',
                                    fontWeight: 'bold',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Subtle shadow for badge
                                }}
                            >
                                值班中
                            </span>
                        )}
                    </div>
                ))}
            </div>

            <h2>選擇您要查詢的棟、樓、室</h2>

            {/* Step 1: Select Building (棟) */}
            {!selectedBuilding && (
                <div>
                    <h4>選擇棟:</h4>
                    {getBuildings().map((building, index) => {
                        // Find the resident with the given "棟" to get the "住址號碼"
                        const resident = residents.find(res => res.棟 === building);
                        const buildingAddressNumber = resident?.住址號碼 || 'N/A';

                        return (
                            <button
                                key={index}
                                className="btn btn-primary"
                                style={{
                                    ...largeButtonStyle,
                                    textAlign: 'center', // Center the text inside the button
                                }}
                                onClick={() => setSelectedBuilding(building)}
                            >
                                {/* First line: Building */}
                                <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                                    {building} 棟
                                </div>
                                {/* Second line: Address number in smaller text */}
                                <div style={{ fontSize: '1.25rem', opacity: 0.8 }}>
                                    學勤路: {buildingAddressNumber}
                                </div>
                            </button>
                        );
                    })}
                </div>
            )}

            {/* Step 2: Select Floor (樓) */}
            {selectedBuilding && !selectedFloor && (
                <div>
                    <h4>選擇樓 (棟: {selectedBuilding}):</h4>
                    {getFloors(selectedBuilding).map((floor, index) => (
                        <button
                            key={index}
                            style={{
                                fontSize: '2rem', // Moderate size for a balanced look
                                padding: '15px 30px', // Adjust padding for a well-proportioned button
                                minWidth: '180px', // Slightly wider to accommodate larger text
                                margin: '8px', // Slightly larger margin for spacing between buttons
                                backgroundColor: '#28a745', // Green background
                                border: '2px solid #218838', // Darker green border for contrast
                                color: '#fff', // White text
                                borderRadius: '8px', // Rounded corners for a softer appearance
                                cursor: 'pointer', // Pointer cursor for better UX
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                                transition: 'transform 0.2s, box-shadow 0.2s', // Smooth transition on hover
                            }}
                            onClick={() => setSelectedFloor(floor)}
                        >
                            {floor} 樓
                        </button>

                    ))}
                    <button
                        className="btn btn-warning"
                        style={largeButtonStyle}
                        onClick={() => setSelectedBuilding(null)}
                    >
                        返回棟選擇
                    </button>
                </div>
            )}

            {/* Step 3: Select Room (室) */}
            {selectedBuilding && selectedFloor && (
                <div>
                    <h4>
                        選擇室 (
                        <span style={{
                            fontWeight: 'bold',
                            color: '#007bff', // Blue color to match Bootstrap's primary color
                            backgroundColor: '#e9ecef', // Light gray background for emphasis
                            padding: '5px 10px', // Padding for better readability
                            borderRadius: '5px', // Rounded corners for a polished look
                        }}>
                            已選擇 : {selectedBuilding} 棟 - {selectedFloor}樓
                        </span>
                        <span style={{
                            fontWeight: 'bold',
                            color: '#28a745', // Green color for contrast
                            marginLeft: '10px', // Space between the two pieces of text
                        }}>
                            或 學勤路: {addressNumber} - {selectedFloor}樓
                        </span>
                        ):
                    </h4>

                    {getRooms(selectedBuilding, selectedFloor).map((resident, index) => (
                        <button
                            key={index}
                            className="btn btn-info"
                            style={{
                                ...largeButtonStyle,
                                textAlign: 'center', // Center the text inside the button
                            }}
                            onClick={() => handleRoomClick(resident)}
                        >
                            {/* First line: Resident last name + "府" */}
                            <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                                {resident.姓}府
                            </div>
                            {/* Second line: Room number */}
                            <div style={{ fontSize: '1.5rem', opacity: 0.8 }}>
                                {resident.室} 室
                            </div>
                        </button>
                    ))}

                    <button
                        className="btn btn-warning"
                        style={largeButtonStyle}
                        onClick={() => setSelectedFloor(null)}
                    >
                        返回樓選擇
                    </button>
                </div>
            )}
        </div>
    );
}

export default Contacts;
