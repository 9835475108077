import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import styled from "styled-components";
import config from './config'; // Adjust the import path if needed

const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
    @media (min-width: 768px) {
        width: 80%;
    }
    @media (min-width: 1024px) {
        width: 70%;
    }
`;

const VideoWrapper = styled.div`
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    position: relative; /* Allows positioning of status text */
    @media (min-width: 768px) {
        width: 48%;
    }
`;

const LocalVideoWrapper = styled(VideoWrapper)`
    width: 20%;
`;

const StyledVideo = styled.video`
    width: 100%;
    height: auto;
    border-radius: 8px;
    max-width: 640px;
`;

const StatusText = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 0.8rem;
`;

const Video = (props) => {
    const ref = useRef();

    useEffect(() => {
        props.peer.on("stream", (stream) => {
            ref.current.srcObject = stream;
        });
    }, [props.peer]);

    return <StyledVideo playsInline autoPlay ref={ref} />;
};

const videoConstraints = {
    width: { ideal: 640 },
    height: { ideal: 360 },
};

const Room = (props) => {
    const [peers, setPeers] = useState([]);
    const [peerStatuses, setPeerStatuses] = useState({}); // State to track peer statuses
    const socketRef = useRef();
    const userVideo = useRef();
    const peersRef = useRef([]);
    const roomID = props.match.params.roomID;

    useEffect(() => {
        // socketRef.current = io.connect("/");
        socketRef.current = io.connect(config.SOCKET_SERVER_URL);
        // socketRef.current = io.connect('https://officesocketio.ngrok.io/');
        navigator.mediaDevices.getUserMedia({
            video: videoConstraints,
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true,
            },
        }).then((stream) => {
            userVideo.current.srcObject = stream;
            socketRef.current.emit("join room", roomID);

            socketRef.current.on("all users", (users) => {
                const peers = [];
                users.forEach((userID) => {
                    const peer = createPeer(userID, socketRef.current.id, stream);
                    peersRef.current.push({
                        peerID: userID,
                        peer,
                    });
                    setPeerStatuses((prevStatuses) => ({
                        ...prevStatuses,
                        [userID]: "Connected",
                    }));
                    peers.push(peer);
                });
                setPeers(peers);
            });

            socketRef.current.on("user joined", (payload) => {
                const peer = addPeer(payload.signal, payload.callerID, stream);
                peersRef.current.push({
                    peerID: payload.callerID,
                    peer,
                });
                setPeerStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    [payload.callerID]: "Connected",
                }));
                setPeers((users) => [...users, peer]);
            });

            socketRef.current.on("user disconnected", (userID) => {
                setPeerStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    [userID]: "Disconnected",
                }));
                peersRef.current = peersRef.current.filter((p) => p.peerID !== userID);
                setPeers((peers) => peers.filter((peer) => peer.peerID !== userID));
            });

            socketRef.current.on("receiving returned signal", (payload) => {
                const item = peersRef.current.find((p) => p.peerID === payload.id);
                item.peer.signal(payload.signal);
            });
        }).catch((error) => {
            console.error("Error accessing media devices.", error);
        });
    }, [roomID]);

    function createPeer(userToSignal, callerID, stream) {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream,
            config: {
                iceServers: [{ urls: "stun:global.stun.twilio.com:3478" }],
                iceCandidatePoolSize: 10,
            },
        });

        peer.on("signal", (signal) => {
            socketRef.current.emit("sending signal", { userToSignal, callerID, signal });
        });

        return peer;
    }

    function addPeer(incomingSignal, callerID, stream) {
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream,
            config: {
                iceServers: [{ urls: "stun:global.stun.twilio.com:3478" }],
                iceCandidatePoolSize: 10,
            },
        });

        peer.on("signal", (signal) => {
            socketRef.current.emit("returning signal", { signal, callerID });
        });

        peer.signal(incomingSignal);

        return peer;
    }

    return (
        <Container>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "20px" }}>
                {peers.map((peer, index) => (
                    <VideoWrapper key={index}>
                        <StatusText>{peerStatuses[peersRef.current[index].peerID]}</StatusText>
                        <h4>遠端 {index + 1}</h4> {/* Display index here for each participant */}
                        <Video peer={peer} />
                    </VideoWrapper>
                ))}
            </div>

            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px" }}>
                <LocalVideoWrapper>
                    <h4>您</h4> {/* This is for the local user video */}
                    <StyledVideo muted ref={userVideo} autoPlay playsInline />
                </LocalVideoWrapper>

                <div style={{ width: "70%" }}>
                    <h4>門口相機</h4>
                    <img
                        src="https://dormakaba.rokka.io/prod-detail-square/7b1bd0/1666880385_Argus-Air-Lounge_2000px-min.jpg"
                        alt="門口相機(模)"
                        style={{ width: "100%", borderRadius: "8px" }}
                    />
                </div>
            </div>
            <div style={{ backgroundColor: "#e3f2fd", padding: "20px", borderRadius: "8px", width: "100%", marginTop: "10px" }}>
                <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: "10px" }}>
                    <h4>遠端開門</h4>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%", marginTop: "10px" }}>
                    {["前門", "倉庫", "後門", "研發"].map((label) => (
                        <button
                            key={label}
                            style={{
                                width: "45%",
                                backgroundColor: "#007bff", // Primary color
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                padding: "10px 0",
                                marginBottom: "20px",
                                cursor: "pointer",
                                fontSize: "1.2rem",
                            }}
                            title="點擊開啟"
                            onClick={(e) => e.target.style.backgroundColor = '#0056b3'} // Click effect to change color
                            onMouseUp={(e) => e.target.style.backgroundColor = '#007bff'} // Revert color after click
                        >
                            {label}
                        </button>
                    ))}
                </div>
            </div>



        </Container>

    );
};

export default Room;
