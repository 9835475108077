import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CreateRoom from "./routes/CreateRoom";
import Room from "./routes/Room";
import RoomMobile from "./routes/RoomMobile";
import Help from "./routes/Help";
import Record from "./routes/Record";
import Younger from "./routes/Younger";
import Search from "./routes/Search";
import Construction from "./routes/Construction";
import ConstructionSearch from "./routes/ConstructionSearch";
import Hotel from "./routes/Hotel";
import HotelSearch from "./routes/HotelSearch";
import Clothing from "./routes/Clothing"; // Import the Clothing page
import ClothingSearch from "./routes/ClothingSearch"; // Import the ClothingSearch page
import CustomLayout from "./layouts/CustomLayout"; // Import the CustomLayout component
import YoungerLayout from "./layouts/YoungerLayout"; // 添加這行
import Call from "./routes/Call";
import Test from "./routes/Test";
import Community from "./routes/Community";
import Ocr from "./routes/Ocr";
import ConCall from "./routes/ConCall"; // Import the ConCall page
import Contacts from "./routes/Contacts"; // Import the Contacts page
import Door from "./routes/Door"; // Import the Contacts page
import Mqtt from "./routes/Mqtt"; // Import the Mqtt page
import Stream from "./routes/Stream"; // Import the Mqtt page

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Door} />
        <Route path="/room" exact component={CreateRoom} />
        <Route path="/room/:roomID" component={Room} />
        <Route path="/roommobile/:roomID" component={RoomMobile} />
        <Route path="/help" component={Help} />
        <Route path="/record" component={Record} />
        <Route path="/search" component={Search} />
        <Route path="/call" component={Call} />
        <Route path="/test" component={Test} />
        <Route path="/community" component={Community} />
        <Route path="/ocr" component={Ocr} />
        <Route path="/concall" component={ConCall} /> {/* Add this route */}
        <Route path="/contacts" component={Contacts} />
        <Route path="/door" component={Door} />
        <Route path="/mqtt" component={Mqtt} />
        <Route path="/stream" component={Stream} />

        {/* Wrap specific routes with the CustomLayout */}
        <Route path="/construction">
          <CustomLayout>
            <Construction />
          </CustomLayout>
        </Route>

        <Route path="/constructionsearch">
          <CustomLayout>
            <ConstructionSearch />
          </CustomLayout>
        </Route>

        <Route path="/hotel">
          <CustomLayout>
            <Hotel />
          </CustomLayout>
        </Route>

        <Route path="/hotelsearch">
          <CustomLayout>
            <HotelSearch />
          </CustomLayout>
        </Route>

        {/* Add new routes for Clothing and ClothingSearch */}
        <Route path="/clothing">
          <YoungerLayout>
            <Clothing />
          </YoungerLayout>
        </Route>

        <Route path="/clothingsearch">
          <YoungerLayout>
            <ClothingSearch />
          </YoungerLayout>
        </Route>

        {/* Use YoungerLayout to wrap Younger, Clothing, and ClothingSearch */}
        <Route path="/younger">
          <YoungerLayout>
            <Younger />
          </YoungerLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
