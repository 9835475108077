// Stream.js
import React, { useEffect, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const Stream = () => {
    const [streamUrl, setStreamUrl] = useState(null);
    const [error, setError] = useState(null);
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);

    // Function to start the stream
    const startStream = async () => {
        try {
            const response = await fetch('https://office6666.dtix.tw/start-stream');
            const data = await response.json();
            console.log(data.message);
        } catch (error) {
            console.error("Error starting stream:", error);
            setError("Could not start the stream.");
        }
    };

    // Function to check stream availability and get the HLS URL
    const fetchStreamUrl = async () => {
        try {
            const response = await fetch('https://office6666.dtix.tw/check-stream');
            if (!response.ok) {
                throw new Error("Stream not available.");
            }
            const data = await response.json();
            setStreamUrl(`https://office6666.dtix.tw${data.url}`);
        } catch (error) {
            console.error("Error fetching stream URL:", error);
            setError("Stream is not currently available.");
        }
    };

    // Initialize the video player when stream URL is available
    useEffect(() => {
        startStream(); // Start the stream on component mount
        fetchStreamUrl(); // Fetch the stream URL

        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
            }
        };
    }, []);

    // Set up the video player when stream URL changes
    useEffect(() => {
        if (streamUrl && videoRef.current) {
            playerRef.current = videojs(videoRef.current, {
                controls: true,
                autoplay: true,
                preload: 'auto',
                responsive: true,
                fluid: true,
                sources: [{
                    src: streamUrl,
                    type: 'application/x-mpegURL'
                }]
            });
        }
    }, [streamUrl]);

    return (
        <div className="stream-container">
            <h2>Live Stream</h2>
            {error && <p className="error">{error}</p>}
            {!error && (
                <div data-vjs-player>
                    <video ref={videoRef} className="video-js vjs-default-skin vjs-big-play-centered" />
                </div>
            )}
        </div>
    );
};

export default Stream;

