import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { FaRedo, FaUpload, FaCircle, FaExclamationTriangle } from 'react-icons/fa'; // Import FontAwesome icons
import config from './config';
import moment from 'moment';
import 'moment/locale/zh-tw';

moment.locale('zh-tw');

function Construction() {
    const [isCameraOpen, setIsCameraOpen] = useState(true); // Camera is open by default
    const [uploadedImagePath, setUploadedImagePath] = useState(''); // State to store the image URL
    const [responseData, setResponseData] = useState(null); // State to store the AI response data
    const [cameraError, setCameraError] = useState(false); // State to track camera errors
    const [loading, setLoading] = useState(false); // State to track loading status
    const [noClothingDetected, setNoClothingDetected] = useState(false); // State to track if no clothing was detected
    const [videoConstraints, setVideoConstraints] = useState({ facingMode: 'user' }); // Default to front camera
    const [pastedImage, setPastedImage] = useState(null); // State to store pasted image
    const [modalVisible, setModalVisible] = useState(false); // State for success modal
    const [deletedOrderId, setDeletedOrderId] = useState(null); // State for deletion confirmation
    const [errorModalVisible, setErrorModalVisible] = useState(false); // State for error modal
    const webcamRef = useRef(null);
    const fileInputRef = useRef(null);

    const keyTranslations = {
        number_of_people: '人數',
        anyone_not_wearing_helmets: '有人未戴安全帽',
        clothing: '衣著',
        activity: '活動',
        helmets: '安全帽',
        position: '位置',
        location: '位置描述',
        ground: '地面',
        sky: '天空',
        lighting: '光線',
        equipment_quantity: '設備數量',
        equipment_type: '設備類型',
        dangerous_environment: '危險環境',
        people_smoking: '有人吸煙',
        description: '說明',
        name: "相機名稱",
        timestamp: "時間",
        image_url: '相片',
        thumbnail_url: '縮圖',
        accident_event: '事故事件',
        warning: '警告'
    };

    useEffect(() => {
        setUploadedImagePath('');
        setNoClothingDetected(false);

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            setVideoConstraints({ facingMode: { exact: 'environment' } });
        } else {
            setVideoConstraints({ facingMode: 'user' });
        }
    }, []);

    const clearAllDataExceptImage = () => {
        setResponseData(null);
        setNoClothingDetected(false);
        setIsCameraOpen(false); // Keep the camera off if an image is uploaded
        setModalVisible(false);
        setDeletedOrderId(null);
        setCameraError(false);
    };

    const handleTakeSnapshot = async () => {
        clearAllDataExceptImage();

        if (webcamRef.current) {
            const image = webcamRef.current.getScreenshot();
            if (image) {
                setPastedImage(image);
                setIsCameraOpen(false);
                await uploadFile(image);
            } else {
                console.error('Failed to capture the screenshot from the webcam.');
            }
        }
    };

    const handleUploadClick = async () => {
        clearAllDataExceptImage();

        if (pastedImage) {
            await uploadFile(pastedImage);
        } else if (isCameraOpen && webcamRef.current) {
            const image = webcamRef.current.getScreenshot();
            if (image) {
                setPastedImage(image);
                setIsCameraOpen(false);
                await uploadFile(image);
            }
        }
    };

    const handleStartOver = () => {
        if (webcamRef.current && webcamRef.current.stream) {
            webcamRef.current.stream.getTracks().forEach(track => track.stop());
        }
        window.location.reload();
    };

    const handleFileChange = async (event) => {
        clearAllDataExceptImage();

        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPastedImage(reader.result);
                setIsCameraOpen(false);
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePaste = async (event) => {
        clearAllDataExceptImage();

        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPastedImage(reader.result);
                    setIsCameraOpen(false);
                };
                reader.readAsDataURL(blob);
            }
        }
    };

    const uploadFile = async (image) => {
        const filename = new Date().toISOString() + '.jpg';
        const blob = await fetch(image).then(res => res.blob());

        let formData = new FormData();
        formData.append('file', blob, filename);

        setLoading(true);

        try {
            const response = await fetch(config.media2_upload, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('檔案上傳失敗');
            }

            const data = await response.json();
            console.log('檔案上傳成功:', data);
            setUploadedImagePath(data.imgUrl);

            const thumbnailUrl = data.thumbnailUrl || '';

            const aiResponse = await postImageAI(data.imgUrl, thumbnailUrl);
            setResponseData(aiResponse);
        } catch (error) {
            console.error('檔案上傳失敗:', error);
            alert('檔案上傳失敗: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const postImageAI = async (imageUrl, thumbnailUrl) => {
        try {
            const response = await axios.post(config.aiconstruction, {
                command: "image_ai",
                image_url: imageUrl,
                thumbnail_url: thumbnailUrl
            });
            console.log('AI Processing Success:', JSON.stringify(response.data, null, 2));

            if (response.data === null) {
                console.log('無衣物被偵測');
                setNoClothingDetected(true);
            }

            return response.data;
        } catch (error) {
            console.error('AI Processing Failed:', error);

            if (error.response && error.response.status === 400) {
                setErrorModalVisible(true); // Show error modal on 400 error
            }

            return null;
        }
    };

    const closeErrorModal = () => {
        setErrorModalVisible(false);
        window.location.reload(); // Reload the page to start fresh
    };

    const closeModal = () => {
        setModalVisible(false);
        setDeletedOrderId(null);
        window.location.reload();
    };

    return (
        <div className="container text-center mt-4 position-relative">
            <h1 className="position-relative">
            【 工地 】單一相機上傳AI辨識
                {!loading && (
                    <button
                        onClick={handleStartOver}
                        className="btn btn-primary position-absolute"
                        style={{ right: '10px', fontSize: '1.5rem', padding: '0.5rem', marginLeft: '20px' }}
                    >
                        <FaRedo />
                    </button>
                )}
            </h1>

            <p>點擊相機預覽拍攝照片，或上傳圖片。</p>

            <div className="row justify-content-center mt-4">
                <div className="col-md-6 col-sm-12 d-flex align-items-center">
                    <button
                        type="button"
                        className="btn btn-secondary me-2"
                        onClick={() => fileInputRef.current.click()}
                    >
                        +
                    </button>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="在此處貼上圖片或拖動圖片"
                        onPaste={handlePaste}
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <button
                        type="button"
                        className="btn btn-primary ms-2 d-flex justify-content-center align-items-center"
                        onClick={handleUploadClick}
                        style={{ width: '100px' }}
                    >
                        <FaUpload className="me-1" /> 上傳
                    </button>
                </div>
            </div>

            {pastedImage && (
                <div className="d-flex justify-content-center">
                    <img src={pastedImage} alt="已貼上的照片" className="img-thumbnail" style={{ maxWidth: '350px', marginTop: '10px' }} />
                </div>
            )}

            {isCameraOpen && !uploadedImagePath && (
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6 col-sm-12">
                        <div className="position-relative">
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width="100%"
                                videoConstraints={videoConstraints}
                                onUserMediaError={() => setCameraError(true)}
                                onClick={handleTakeSnapshot}
                                style={{ cursor: 'pointer' }}
                            />
                            {cameraError && (
                                <div className="position-absolute top-50 start-50 translate-middle text-center text-danger fw-bold">
                                    相機預覽失敗
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {loading && (
                <div style={{
                    position: 'absolute',
                    top: '38%',
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    textAlign: 'center',
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '1rem',
                    borderRadius: '10px',
                    zIndex: 1000,
                }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-2">AI 分析中，請稍候...</p>
                </div>
            )}

            {noClothingDetected && !loading && (
                <div className="text-danger fw-bold mt-4">
                    無衣物被偵測或無法分析
                </div>
            )}

            {responseData && (
                <div className="mt-3">
                    <h5>
                        AI分析結果
                        <span style={{ marginLeft: '15px' }}>
                            {responseData.warning ? (
                                <FaCircle style={{ color: 'red', fontSize: '3rem' }} />
                            ) : (
                                <FaCircle style={{ color: 'green', fontSize: '3rem' }} />
                            )}
                        </span>
                    </h5>

                    <table className="table table-bordered">
                        <tbody>
                            {Object.entries(responseData).map(([key, value]) => {
                                if (value === '' || value === null || value === undefined || key === 'id' || key === 'time') {
                                    return null;
                                }

                                if (key === 'timestamp') {
                                    value = moment(value).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss');
                                }

                                return (
                                    <tr key={key}>
                                        <th style={{ minWidth: '150px' }}>{keyTranslations[key] || key}</th>
                                        <td style={{ maxWidth: '600px', whiteSpace: 'normal', overflow: 'hidden' }}>
                                            {key === 'image_url' || key === 'thumbnail_url' ? (
                                                <img src={value} alt="AI generated content" style={{ width: key === 'thumbnail_url' ? '200px' : '500px' }} />
                                            ) : Array.isArray(value) ? (
                                                value.join(', ')
                                            ) : typeof value === 'boolean' ? (
                                                value ? '是' : '否'
                                            ) : (
                                                value
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Modal for Deletion Confirmation */}
            {modalVisible && (
                <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">訂單刪除成功</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>訂單號碼 {deletedOrderId} 已成功刪除。</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={closeModal}>確定</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Error (Invalid Image) */}
            {errorModalVisible && (
                <Modal
                    show={errorModalVisible}
                    onHide={closeErrorModal}
                    centered
                    dialogClassName="custom-modal-dialog" // Use your custom modal dialog class
                >
                    <Modal.Body className="custom-modal-body d-flex justify-content-center align-items-center p-0 position-relative">
                        <button
                            onClick={closeErrorModal}
                            className="close-button"
                        >
                            &times;
                        </button>
                        <div style={{
                            textAlign: 'center',
                            color: 'red',
                            fontSize: '1.5rem',
                            padding: '1rem',
                            width: '100%',
                            maxWidth: '500px'
                        }}>
                            <FaExclamationTriangle style={{ fontSize: '3rem', color: 'red' }} />
                            <p className="mt-2">圖片無效，請上傳正確的圖片。</p>
                            <button type="button" className="btn btn-danger" onClick={closeErrorModal}>重新上傳</button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}

        </div>
    );
}

export default Construction;
