import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { FaRedo, FaUpload, FaTrash } from 'react-icons/fa';
import config from './config01';

function Younger() {
    const [isCameraOpen, setIsCameraOpen] = useState(true);
    const [uploadedImagePath, setUploadedImagePath] = useState('');
    const [responseData, setResponseData] = useState(null);
    const [cameraError, setCameraError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [noClothingDetected, setNoClothingDetected] = useState(false);
    const [videoConstraints, setVideoConstraints] = useState({ facingMode: 'user' });
    const [pastedImage, setPastedImage] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [deletedOrderId, setDeletedOrderId] = useState(null);
    const webcamRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        setUploadedImagePath('');
        setNoClothingDetected(false);

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            setVideoConstraints({ facingMode: { exact: 'environment' } });
        } else {
            setVideoConstraints({ facingMode: 'user' });
        }
    }, []);

    const clearAllDataExceptImage = () => {
        setResponseData(null);
        setNoClothingDetected(false);
        setIsCameraOpen(false);
        setModalVisible(false);
        setDeletedOrderId(null);
        setCameraError(false);
    };

    const handleTakeSnapshot = async () => {
        clearAllDataExceptImage();

        if (webcamRef.current) {
            const image = webcamRef.current.getScreenshot();
            if (image) {
                setPastedImage(image);
                setIsCameraOpen(false);
                await uploadFile(image);
            } else {
                console.error('Failed to capture the screenshot from the webcam.');
            }
        }
    };

    const handleUploadClick = async () => {
        clearAllDataExceptImage();

        if (pastedImage) {
            await uploadFile(pastedImage);
        } else if (isCameraOpen && webcamRef.current) {
            const image = webcamRef.current.getScreenshot();
            if (image) {
                setPastedImage(image);
                setIsCameraOpen(false);
                await uploadFile(image);
            }
        }
    };

    const handleStartOver = () => {
        if (webcamRef.current && webcamRef.current.stream) {
            webcamRef.current.stream.getTracks().forEach(track => track.stop());
        }

        window.location.reload();
    };

    const handleFileChange = async (event) => {
        clearAllDataExceptImage();

        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPastedImage(reader.result);
                setIsCameraOpen(false);
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePaste = async (event) => {
        clearAllDataExceptImage();

        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPastedImage(reader.result);
                    setIsCameraOpen(false);
                };
                reader.readAsDataURL(blob);
            }
        }
    };

    const handleKeyPress = (event) => {
        event.preventDefault();
    };

    const uploadFile = async (image) => {
        const filename = new Date().toISOString() + '.jpg';
        const blob = await fetch(image).then(res => res.blob());

        let formData = new FormData();
        formData.append('file', blob, filename);

        setLoading(true);

        try {
            const response = await fetch(config.media2_younger_upload, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('檔案上傳失敗');
            }

            const data = await response.json();
            console.log('檔案上傳成功:', data);
            setUploadedImagePath(data.imgUrl);

            const thumbnailUrl = data.thumbnailUrl || '';

            const aiResponse = await postImageAI(data.imgUrl, thumbnailUrl);
            console.log('AI Response:', aiResponse);

            if (aiResponse && typeof aiResponse === 'object') {
                setResponseData(aiResponse);
            } else {
                console.error('Invalid AI response:', aiResponse);
                setResponseData(null);
                setNoClothingDetected(true);
            }
        } catch (error) {
            console.error('檔案上傳失敗:', error);
            alert('檔案上傳失敗: ' + error.message);
            setResponseData(null);
            setNoClothingDetected(true);
        } finally {
            setLoading(false);
        }
    };

    const postImageAI = async (imageUrl, thumbnailUrl) => {
        try {
            const response = await axios.post(config.younger01, {
                command: "image_ai",
                image_url: imageUrl,
                thumbnail_url: thumbnailUrl
            });
            console.log('AI Processing Success:', JSON.stringify(response.data, null, 2));
            if (response.data === null) {
                console.log('無衣物被偵測');
                setNoClothingDetected(true);
            }
            return response.data;
        } catch (error) {
            console.error('AI Processing Failed:', error);
            return null;
        }
    };

    const handleDeleteOrder = async (orderId) => {
        try {
            const response = await axios.post(config.younger01, {
                command: "delete_order",
                order_id: orderId
            });
            if (response.data.success === true) {
                setDeletedOrderId(orderId);
                setModalVisible(true);
            } else {
                alert('刪除訂單失敗');
            }
        } catch (error) {
            console.error('刪除訂單失敗:', error);
            alert('刪除訂單失敗: ' + error.message);
        }
    };

    const closeModal = () => {
        setModalVisible(false);
        setDeletedOrderId(null);
        window.location.reload();
    };

    return (
        <div className="container text-center mt-4 position-relative">
            <div className="position-relative">
                <h1>歡迎來到 Younger 頁面</h1>
                {!loading && (
                    <button
                        onClick={handleStartOver}
                        className="btn btn-primary position-fixed"
                        style={{
                            top: '10px',
                            right: '10px',
                            fontSize: '1.5rem',
                            padding: '0.5rem',
                            zIndex: 1000
                        }}
                    >
                        <FaRedo />
                    </button>
                )}
            </div>

            <p>點擊相機預覽拍攝照片，或上傳圖片。</p>

            <div className="row justify-content-center mt-4">
                <div className="col-md-6 col-sm-12 d-flex align-items-center">
                    <button
                        type="button"
                        className="btn btn-secondary me-2"
                        onClick={() => fileInputRef.current.click()}
                    >
                        +
                    </button>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="在此處貼上圖片或拖動圖片"
                        onPaste={handlePaste}
                        onKeyPress={handleKeyPress}
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <button
                        type="button"
                        className="btn btn-primary ms-2 d-flex justify-content-center align-items-center"
                        onClick={handleUploadClick}
                        style={{ width: '100px' }}
                    >
                        <FaUpload className="me-1" /> 上傳
                    </button>
                </div>
            </div>

            {pastedImage && (
                <div className="d-flex justify-content-center">
                    <img src={pastedImage} alt="已貼上的照片" className="img-thumbnail" style={{ maxWidth: '350px', marginTop: '10px' }} />
                </div>
            )}

            {isCameraOpen && !uploadedImagePath && (
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6 col-sm-12">
                        <div className="position-relative">
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width="100%"
                                videoConstraints={videoConstraints}
                                onUserMediaError={() => setCameraError(true)}
                                onClick={handleTakeSnapshot}
                                style={{ cursor: 'pointer' }}
                            />
                            {cameraError && (
                                <div className="position-absolute top-50 start-50 translate-middle text-center text-danger fw-bold">
                                    相機預覽失敗
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {loading && (
                <div style={{
                    position: 'absolute',
                    top: '38%',
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    textAlign: 'center',
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '1rem',
                    borderRadius: '10px',
                    zIndex: 1000,
                }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-2">AI 分析中，請稍候...</p>
                </div>
            )}

            {noClothingDetected && !loading && (
                <div className="text-danger fw-bold mt-4">
                    無衣物被偵測或無法分析
                </div>
            )}

            {responseData && !loading && (
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6 col-sm-12">
                        <h3>AI 分析結果</h3>
                        <table className="table table-bordered mt-3">
                            <tbody>
                                <tr><th>訂單</th><td>{responseData.order_id}</td></tr>
                                <tr><th>時間</th><td>{new Date(responseData.timestamp).toLocaleString()}</td></tr>
                                <tr><th>價格</th><td>${responseData.price}</td></tr>
                                <tr><th>主類別</th><td>{responseData.main_category}</td></tr>
                                <tr><th>類型</th><td>{responseData.type}</td></tr>
                                <tr><th>風格</th><td>{responseData.style}</td></tr>
                                <tr><th>圖案</th><td>{responseData.pattern}</td></tr>
                                <tr><th>顏色</th><td>{responseData.colors.join(', ')}</td></tr>
                                <tr><th>材質</th><td>{responseData.material}</td></tr>
                                <tr><th>用途</th><td>{responseData.usage}</td></tr>
                                <tr><th>描述</th><td>{responseData.description}</td></tr>
                                <tr><th>Younger 主類別</th><td>{responseData.younger_main}</td></tr>
                                <tr><th>Younger 子類別</th><td>{responseData.younger_sub}</td></tr>
                                {responseData.thumbnail_url && (
                                    <tr>
                                        <th>縮略圖</th>
                                        <td>
                                            <img
                                                src={responseData.thumbnail_url}
                                                alt="縮略圖"
                                                style={{ maxWidth: '100px', maxHeight: '100px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {responseData.image_url && (
                                    <tr>
                                        <th>原圖</th>
                                        <td>
                                            <img
                                                src={responseData.image_url}
                                                alt="原圖"
                                                style={{ maxWidth: '200px', maxHeight: '200px' }}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-center">
                            <button
                                type="button"
                                className="btn btn-danger mt-3 d-flex align-items-center"
                                onClick={() => handleDeleteOrder(responseData.order_id)}
                            >
                                <FaTrash className="me-2" />
                                <span>刪除訂單</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {modalVisible && (
                <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">訂單刪除成功</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>訂單號碼 {deletedOrderId} 已成功刪除。</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={closeModal}>確定</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default Younger;
