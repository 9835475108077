import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/zh-tw';
import config from './config';
import { FaCamera } from 'react-icons/fa';


moment.locale('zh-tw');

function OCR() {
    const [isCameraOpen, setIsCameraOpen] = useState(true);
    const [pastedImage, setPastedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [countdown, setCountdown] = useState(null); // Countdown state
    const [hasTakenPicture, setHasTakenPicture] = useState(false); // Prevent multiple picture-taking
    const webcamRef = useRef(null);
    const [structuredResponse, setStructuredResponse] = useState(null);
    const countdownTimeout = useRef(null); // Store debounce timeout
    const [errorMessage, setErrorMessage] = useState(null); // State to hold error message


    // Start countdown and take a picture after it reaches 0
    const handleTakeSnapshot = () => {
        console.log('taking image click')
        if (!hasTakenPicture) {
            setHasTakenPicture(true); // Set flag to ensure only one picture is taken
            setCountdown(3); // Start the countdown from 3 seconds

            const countdownInterval = setInterval(() => {
                setCountdown((prev) => {
                    if (prev === 1) {
                        clearInterval(countdownInterval); // Clear interval when countdown ends
                        debounceTakePicture(); // Use debounce to ensure single picture is taken
                        return null;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
    };

    // Debounced function to take picture
    const debounceTakePicture = () => {
        clearTimeout(countdownTimeout.current); // Clear any previous timeouts
        countdownTimeout.current = setTimeout(() => {
            takePicture(); // Take the picture only once
        }, 300); // Debounce time (300ms)
    };

    // Take picture from webcam
    const takePicture = async () => {
        if (webcamRef.current) {
            const image = webcamRef.current.getScreenshot();
            if (image) {
                setPastedImage(image);
                setIsCameraOpen(false);
                await uploadFile(image);
            }
        }
    };

    // Upload file for OCR and AI processing
    const uploadFile = async (image) => {
        const blob = await fetch(image).then(res => res.blob());
        let formData = new FormData();
        formData.append('file', blob, 'image.png');
        setLoading(true);

        try {
            const ocrResponse = await axios.post('https://yolo12138-paddle-ocr-api.hf.space/ocr?lang=ch', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'accept': 'application/json',
                }
            });

            const textArray = ocrResponse.data.map(item => item.txt);
            console.log(textArray);

            if (textArray.length === 0) {
                throw new Error('No scanned data detected'); // Handle case where no data is detected
            }

            const openAiResponse = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: 'gpt-4o-mini',
                    temperature: 0.7,
                    messages: [
                        {
                            role: 'system',
                            content: `你是一名非常智能且細心的文件處理員。你的任務是詢問使用者的姓名及 ID 類型。如果使用者未提供完整資訊，請做出最佳推測並填補空白。請使用以下的 JSON 格式回應。如果資訊不完整，請儘量合理推斷並填寫：
                1. 客戶的姓名
                2. ID 類型（例如公司名稱或發證機構）
                如果需要補充或確認，請適當引導。`,
                        },
                        {
                            role: 'user',
                            content: textArray.join(', '),
                        },
                    ],
                    response_format: {
                        type: 'json_schema',
                        json_schema: {
                            name: 'user_info_response',
                            schema: {
                                type: 'object',
                                properties: {
                                    user_name: { type: 'string', description: '使用者的姓名' },
                                    id_type: { type: 'string', description: 'ID 類型（公司或發證機構）' },
                                    // message: { type: 'string', description: '詢問所需補充資料的訊息' }
                                },
                                required: ['user_name', 'id_type'],
                            },
                        },
                    },
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${config.openAiApiKey}`,
                    },
                }
            );

            const structuredResponseData = JSON.parse(openAiResponse.data.choices[0].message.content);
            setStructuredResponse(structuredResponseData);

        } catch (error) {
            console.error('Upload failedxxxxx:', error);

            // Display appropriate message based on error
            setErrorMessage('未檢測到掃描數據，2 秒後將重新加載...');

            // Reload the page after 2 seconds
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } finally {
            setLoading(false);
            setHasTakenPicture(false); // Allow future picture-taking after process finishes
        }
    };


    return (
        <div className="container text-center mt-4 position-relative">
            <h1 className="position-relative">AI 證件辨識登記</h1>
            <p>點擊相機預覽將倒數三秒拍攝照片，將您的證件放到紅色框框。</p>

            {errorMessage && (
                <p style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</p> // Display the error message in red
            )}


            {pastedImage && (
                <div className="d-flex justify-content-center">
                    <img src={pastedImage} alt="已貼上的照片" className="img-thumbnail" style={{ maxWidth: '350px', marginTop: '10px' }} />
                </div>
            )}

            {isCameraOpen && !pastedImage && (
                <div className="row justify-content-center mt-4 position-relative">
                    <div className="col-md-6 col-sm-12 position-relative">
                        <div style={{ position: 'relative' }}>
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width="100%"
                                style={{ borderRadius: '10px' }}
                            />
                            {/* Red rectangular overlay */}
                            <div
                                style={{
                                    position: 'absolute',
                                    border: '4px solid red',
                                    top: '20%',
                                    left: '20%',
                                    width: '70%',
                                    height: '60%',
                                }}
                            ></div>

                            {/* Left button */}
                            <button
                                onClick={handleTakeSnapshot}
                                className="btn take-photo-btn"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '-100px',
                                    transform: 'translateY(-50%)',
                                    fontSize: '2rem',
                                    width: '80px',
                                    height: '80px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#ff5722', // Bright orange background
                                    border: 'none', // Remove border
                                    color: 'white', // White icon color
                                }}
                            >
                                <FaCamera />
                            </button>

                            {/* Right button */}
                            <button
                                onClick={handleTakeSnapshot}
                                className="btn take-photo-btn"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '-100px',
                                    transform: 'translateY(-50%)',
                                    fontSize: '2rem',
                                    width: '80px',
                                    height: '80px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#ff5722', // Bright orange background
                                    border: 'none', // Remove border
                                    color: 'white', // White icon color
                                }}
                            >
                                <FaCamera />
                            </button>




                        </div>
                    </div>
                </div>
            )}

            {countdown !== null && (
                <div className="countdown" style={{ fontSize: '4rem', color: 'red' }}>
                    {countdown} {/* Display countdown */}
                </div>
            )}

            {loading && (
                <div className="text-center text-white bg-dark p-3 mt-4">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-2">AI 分析中，請稍候...</p>
                </div>
            )}

            {structuredResponse && (
                <div className="mt-3">
                    <h5>偵測訪客資訊</h5>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <span style={{ fontWeight: 'lighter', color: '#6c757d' }}>姓名: </span>
                            <span style={{ fontWeight: 'bold', color: '#343a40', fontSize: '1.2rem' }}>{structuredResponse.user_name}</span>
                        </li>
                        <li className="list-group-item">
                            <span style={{ fontWeight: 'lighter', color: '#6c757d' }}>證件類型: </span>
                            <span style={{ fontWeight: 'bold', color: '#343a40', fontSize: '1.2rem' }}>{structuredResponse.id_type}</span>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default OCR;
