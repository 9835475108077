import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import styled, { keyframes } from "styled-components";
import config from './config'; // Adjust the import path if needed

const Container = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between; /* Space between items */
    align-items: flex-start;
    gap: 20px;
    width: 100%; /* Ensure container takes full width */
    margin: auto;
`;

const VisitorVideoWrapper = styled.div`
    flex: 0 0 20%; /* Flex-basis set to 20% */
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ParticipantVideoWrapper = styled.div`
    flex: 0 0 70%; /* Flex-basis set to 70% */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;


const StyledVideo = styled.video`
    width: 100%;
    height: auto;
    border-radius: 8px;
    max-height: 360px;
    object-fit: cover;
`;

const StatusText = styled.div`
    width: 80px;
    text-align: center;
    color: white;
    padding: 5px 0;
    font-size: 0.8rem;
    background-color: ${({ status }) =>
        status === "已連接" ? "rgba(0, 128, 0, 0.7)" : "rgba(255, 0, 0, 0.7)"};
    border-radius: 8px;
`;

const StyledH4 = styled.h4`
    color: white;
    margin: 10px 0;
`;


// Define keyframes for the ringing effect animation with multiple colors and size changes
const ringAnimation = keyframes`
    0% {
        transform: scale(0.6);
        opacity: 0.6;
        border-color: #ff4500; /* Orange-red color */
        width: 50px;
        height: 50px;
    }
    25% {
        transform: scale(0.8);
        opacity: 0.7;
        border-color: #ffa500; /* Orange color */
        width: 60px;
        height: 60px;
    }
    50% {
        transform: scale(1);
        opacity: 1;
        border-color: #00ff00; /* Green color */
        width: 70px;
        height: 70px;
    }
    75% {
        transform: scale(1.2);
        opacity: 0.7;
        border-color: #0000ff; /* Blue color */
        width: 60px;
        height: 60px;
    }
    100% {
        transform: scale(0.6);
        opacity: 0.6;
        border-color: #ff4500; /* Back to Orange-red */
        width: 50px;
        height: 50px;
    }
`;

// Styled component for the ringing effect with multiple colors and sizes
const RingingEffect = styled.div`
    border: 5px solid transparent; /* Border is animated in the keyframes */
    border-radius: 50%;
    animation: ${ringAnimation} 2s infinite; /* Loop animation with 2s duration */
    position: absolute; /* Use absolute to center within a relative container */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Offset by 50% to perfectly center */
    z-index: 1050; /* Higher than modal but below other UI elements */
`;

// Assuming this is the container you want to center the effect within
const RingingEffectContainer = styled.div`
    position: relative; /* Establish a positioning context for the absolute child */
    width: 100%;
    height: 100%; /* Full height of the parent */
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Video = ({ peer, onStreamError }) => {
    const ref = useRef();

    useEffect(() => {
        peer.on("stream", (stream) => {
            ref.current.srcObject = stream;
        });

        peer.on("close", () => {
            onStreamError("Connection Closed");
        });

        peer.on("error", () => {
            onStreamError("Connection Error");
        });
    }, [peer, onStreamError]);

    return <StyledVideo playsInline autoPlay ref={ref} />;
};

const videoConstraints = {
    width: { ideal: 640 },
    height: { ideal: 360 }
};

const Room = (props) => {
    const [peers, setPeers] = useState([]);
    const [peerStatuses, setPeerStatuses] = useState({});
    const [isRinging, setIsRinging] = useState(true); // State to control the ringing effect visibility
    const socketRef = useRef();
    const userVideo = useRef();
    const peersRef = useRef([]);
    const audioRef = useRef(new Audio('/ring.mp3')); // Ref to store the audio object
    const roomID = props.match.params.roomID;

    useEffect(() => {
        const audio = audioRef.current; // Copy ref value to a local variable

        // Start playing the audio when the component mounts
        audio.play();

        // socketRef.current = io.connect("/");
        //  SOCKET_SERVER_URL: 'http://localhost:5000', 
        socketRef.current = io.connect(config.SOCKET_SERVER_URL);
        navigator.mediaDevices.getUserMedia({
            video: videoConstraints,
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true
            }
        }).then((stream) => {
            userVideo.current.srcObject = stream;
            socketRef.current.emit("join room", roomID);

            socketRef.current.on("all users", (users) => {
                const peers = [];
                users.forEach((userID) => {
                    const peer = createPeer(userID, socketRef.current.id, stream);
                    peersRef.current.push({
                        peerID: userID,
                        peer,
                    });
                    setPeerStatuses((prevStatuses) => ({
                        ...prevStatuses,
                        [userID]: "Connected",
                    }));
                    peers.push(peer);
                });
                setPeers(peers);
            });

            socketRef.current.on("user joined", (payload) => {
                const peer = addPeer(payload.signal, payload.callerID, stream);
                peersRef.current.push({
                    peerID: payload.callerID,
                    peer,
                });
                setPeerStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    [payload.callerID]: "Connected",
                }));
                setPeers((users) => [...users, peer]);
            });

            socketRef.current.on("user disconnected", (userID) => {
                setPeerStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    [userID]: "Disconnected",
                }));
                peersRef.current = peersRef.current.filter((p) => p.peerID !== userID);
                setPeers((peers) => peers.filter((peer) => peer.peerID !== userID));
            });

            socketRef.current.on("receiving returned signal", (payload) => {
                const item = peersRef.current.find((p) => p.peerID === payload.id);
                item.peer.signal(payload.signal);
            });
        }).catch((error) => {
            console.error('Error accessing media devices.', error);
        });

        return () => {
            // Use the local variable for cleanup
            audio.pause();
            audio.currentTime = 0;
            setIsRinging(false); // Hide the ringing effect on cleanup
        };
    }, [roomID]);

    // UseEffect to log when the first peer connects and stop the audio
    useEffect(() => {
        if (Object.values(peerStatuses).includes("Connected")) {
            console.log("The first remote participant (遠端 1) has connected.");
            const audio = audioRef.current; // Copy ref value to a local variable
            audio.pause(); // Stop the audio when the first participant connects
            audio.currentTime = 0; // Reset the audio to the beginning
            setIsRinging(false); // Hide the ringing effect when the first participant connects
        }
    }, [peerStatuses]);

    const handleStreamError = (peerID, message) => {
        setPeerStatuses((prevStatuses) => ({
            ...prevStatuses,
            [peerID]: message,
        }));
    };

    function createPeer(userToSignal, callerID, stream) {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream,
            config: {
                iceServers: [
                    { urls: 'stun:global.stun.twilio.com:3478' }
                ],
                iceCandidatePoolSize: 10
            }
        });

        peer.on("signal", (signal) => {
            socketRef.current.emit("sending signal", { userToSignal, callerID, signal });
        });

        return peer;
    }

    function addPeer(incomingSignal, callerID, stream) {
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream,
            config: {
                iceServers: [
                    { urls: 'stun:global.stun.twilio.com:3478' }
                ],
                iceCandidatePoolSize: 10
            }
        });

        peer.on("signal", (signal) => {
            socketRef.current.emit("returning signal", { signal, callerID });
        });

        peer.signal(incomingSignal);

        return peer;
    }

    return (
        <Container>
            {/* Show the ringing effect when isRinging is true */}
            <RingingEffectContainer>
                {isRinging && <RingingEffect />}
            </RingingEffectContainer>

            <VisitorVideoWrapper>
                <StatusText status="已連接">已連接</StatusText> {/* Changed to Traditional Chinese */}
                <StyledH4>您</StyledH4>
                <StyledVideo muted ref={userVideo} autoPlay playsInline />
            </VisitorVideoWrapper>
            {peers.map((peer, index) => (
                <ParticipantVideoWrapper key={index}>
                    <StatusText status={peerStatuses[peersRef.current[index]?.peerID] === "Connected" ? "已連接" : "未連接"}>
                        {peerStatuses[peersRef.current[index]?.peerID] === "Connected" ? "已連接" : "未連接"}
                    </StatusText> {/* Corrected logic to dynamically change status */}
                    <StyledH4>遠端 {index + 1}</StyledH4> {/* Ensure visibility */}
                    <Video peer={peer} onStreamError={(message) => handleStreamError(peersRef.current[index].peerID, message)} />
                </ParticipantVideoWrapper>
            ))}
        </Container>
    );
};

export default Room;
