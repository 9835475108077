import React, { useEffect, useState } from 'react';
import { InstantSearch, connectHits, connectSearchBox, Configure, Pagination } from 'react-instantsearch-dom';  // Import Pagination
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { Card, Row, Col, Form, InputGroup, Button, Modal } from 'react-bootstrap';
import {
    FaUsers, FaTshirt, FaPalette, FaLeaf, FaTag, FaThLarge, FaUser, FaCalendarAlt, FaBarcode, FaMoneyBillAlt, FaTrash, FaSearch
} from 'react-icons/fa';
import moment from 'moment';
import 'moment/locale/zh-tw';
import '../App.css'; // or './index.css'

moment.locale('zh-tw');

// Configure the Typesense InstantSearch adapter
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
        apiKey: 'xyzxd23sdsdeeexx',  // Your API key
        nodes: [
            {
                host: 'typesense2.dtix.tw',  // Your Typesense host
                port: '443',  // HTTPS port
                protocol: 'https',
            },
        ],
    },
    additionalSearchParameters: {
        query_by: 'embedding, description',
        hitsPerPage: 1,
        prefix: 'false',
        exclude_fields: 'embedding',
    }
});

const searchClient = typesenseInstantsearchAdapter.searchClient;

// Custom Hit component to display search results with highlights and image
const Hit = ({ hit, onImageClick }) => {
    // console.log(hit);

    const handleDelete = () => {
        fetch(`https://typesense2.dtix.tw/collections/clothing/documents/${hit.id}`, {
            method: 'DELETE',
            headers: {
                'X-TYPESENSE-API-KEY': 'xyzxd23sdsdeeexx',  // Replace 'xyz' with your actual API key
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Document deleted:', data);
                window.location.reload();  // Optionally: refresh the page or use InstantSearch's refresh function
            })
            .catch(error => {
                console.error('Error deleting document:', error);
            });
    };

    const isDangerous = hit.anyone_not_wearing_helmets || hit.people_smoking || (hit.dangerous_environment && hit.dangerous_environment !== "無");

    return (
        <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card className="h-100 position-relative" style={{ border: '1px solid #ddd', borderRadius: '5px', backgroundColor: isDangerous ? '#f8d7da' : '#fff' }}>
                {hit.image_url && (
                    <Card.Img
                        variant="top"
                        src={hit.image_url}
                        alt={hit.description}
                        style={{ maxHeight: '200px', objectFit: 'cover', cursor: 'pointer' }}
                        onClick={() => onImageClick(hit.image_url)}  // Trigger modal on image click
                    />
                )}
                <Card.Body>
                    <Card.Title className="text-primary">{hit.main_category} - {hit.type}</Card.Title>
                    <Card.Text>{hit.description}</Card.Text>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {/* Number of People */}
                        {hit.number_of_people && (
                            <span className="d-flex align-items-center gap-2">
                                <FaUsers style={{ color: '#007bff' }} /> {hit.number_of_people}
                            </span>
                        )}

                        {/* Clothing type */}
                        <span className="d-flex align-items-center gap-2">
                            <FaTshirt style={{ color: '#007bff' }} /> {hit.main_category}
                        </span>

                        {/* Colors */}
                        <span className="d-flex align-items-center gap-2">
                            <FaPalette style={{ color: '#007bff' }} /> {hit.colors.join(', ')}
                        </span>

                        {/* Material */}
                        <span className="d-flex align-items-center gap-2">
                            <FaLeaf style={{ color: '#007bff' }} /> {hit.material}
                        </span>

                        {/* Style */}
                        <span className="d-flex align-items-center gap-2">
                            <FaTag style={{ color: '#007bff' }} /> {hit.style}
                        </span>

                        {/* Pattern */}
                        {hit.pattern && (
                            <span className="d-flex align-items-center gap-2">
                                <FaThLarge style={{ color: '#007bff' }} /> {hit.pattern || '無'}
                            </span>
                        )}

                        {/* Usage */}
                        <span className="d-flex align-items-center gap-2">
                            <FaUser style={{ color: '#007bff' }} /> {hit.usage}
                        </span>

                        {/* Timestamp */}
                        <span className="d-flex align-items-center gap-2">
                            <FaCalendarAlt style={{ color: '#007bff' }} /> {moment(hit.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                        </span>

                        {/* Order ID */}
                        <span className="d-flex align-items-center gap-2">
                            <FaBarcode style={{ color: '#007bff' }} /> {hit.order_id}
                        </span>

                        {/* Price */}
                        <span className="d-flex align-items-center gap-2">
                            <FaMoneyBillAlt style={{ color: '#007bff' }} /> {hit.price}
                        </span>
                    </div>
                </Card.Body>

                {/* Add delete button in the bottom right corner */}
                <button
                    onClick={handleDelete}
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    <FaTrash style={{ color: 'grey' }} />
                </button>
            </Card>
        </Col>
    );
};

// Custom Hits component using connectHits
const CustomHits = ({ hits, onImageClick }) => (
    <Row className="mt-4 hits-container">
        {hits.map(hit => (
            <Hit key={hit.objectID} hit={hit} onImageClick={onImageClick} />
        ))}
    </Row>
);

const ConnectedHits = connectHits(CustomHits);

// Create a custom search box component using connectSearchBox
const CustomSearchBox = ({ currentRefinement, refine }) => (
    <Form onSubmit={e => e.preventDefault()}>
        <InputGroup className="mb-3">
            <Form.Control
                type="text"
                placeholder="Search..."
                aria-label="Search"
                value={currentRefinement}
                onChange={e => refine(e.currentTarget.value)}
            />
            <Button variant="outline-secondary" onClick={() => refine(currentRefinement)}>
                <FaSearch />
            </Button>
        </InputGroup>
    </Form>
);

const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

function ClothingSearch() {
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');

    const handleImageClick = (imageUrl) => {
        setModalImage(imageUrl);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                window.location.reload();  // Reload the page when Enter key is pressed
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown); // Clean up event listener on unmount
        };
    }, []);

    return (
        <div className="container mt-4 text-center">
            <h1>AI洗衣智慧監看</h1>
            <p className="text-center text-danger small">請按Enter更新資料</p>

            <InstantSearch searchClient={searchClient} indexName="clothing">
                <Configure hitsPerPage={10} />  {/* Set number of results per page */}
                <ConnectedSearchBox />
                <ConnectedHits onImageClick={handleImageClick} />

                {/* Pagination Component */}
                <Pagination showLast={true} />  {/* Adds pagination control */}
            </InstantSearch>

            {/* Modal for Enlarged Image */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                dialogClassName="custom-modal-dialog" // Custom modal dialog class
            >
                <Modal.Body className="custom-modal-body d-flex justify-content-center align-items-center p-0 position-relative">
                    <button
                        onClick={handleCloseModal}
                        className="close-button"
                    >
                        &times;
                    </button>
                    <img
                        src={modalImage}
                        alt="Enlarged view"
                        style={{
                            width: '100%',
                            maxWidth: '500px', /* Ensure the image is within the modal width */
                            objectFit: 'contain', /* Make sure the image fits nicely */
                            display: 'block',
                            margin: '0px'
                        }}
                    />
                </Modal.Body>
            </Modal>

        </div>
    );
}

export default ClothingSearch;
