
const config = {
    // MQTT Configuration
  MQTT: {
    brokerURL: 'wss://ws.datavansolutions.com:443/mqtt',
    username: 'datavan_front',
    password: 'datavanpork123',
    topic: 'my/test/topic'
  },
  videoRoomId: "long-5c7e6300-4f7f-4a62-86f4-6b4978a1e1a3",
  SOCKET_SERVER_URL: 'https://serversocketio.ngrok.io',
  // videoChatBaseUrl: 'https://officevideochat.ngrok.io', // Add this line
  // videoChatBaseUrl: 'https://howard3000.ngrok.io', // Add this line
  videoChatBaseUrl: 'https://office7000.dtix.tw', // Add this line
  dtix_upload: 'https://media.dtix.tw/uploadPic/construction',
  media2_upload: 'https://media2.dtix.tw/uploadPic/construction',
  media2_younger_upload: 'https://media2.dtix.tw/uploadPic/younger',
  servicesUrl: 'https://node1prod.dtix.tw/hotel',
  streamUrl: 'https://hotel.datavansolutions.com:9092/stream_rags',
  youngerBase: 'https://media2.frrut.net',
  imageAi: 'https://node1prod.dtix.tw/younger',
  aiconstruction: 'https://node1prod.dtix.tw/aiconstruction',
  linenotify: 'https://node1prod.dtix.tw/videochat',
  openAiApiKey: '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',
  lineNotifyToken: 'p7vsXcExsbBeUNBZH4BPjoliGN0FlQc6z9RA0ZtfTUP',
  officeContacts: [
    { "department": "行政管理部", "title": "行政部長", "name": "Toby Yuen", "chinese_name": "袁龍聖", "phone": "+886912235475", "on_duty": false },
    { "department": "行政管理部", "title": "人資專員", "name": "Roy", "chinese_name": "葉劭俊", "phone": "+886912235475", "on_duty": false },
    { "department": "行政管理部", "title": "文管", "name": "Kato", "chinese_name": "高達鑫", "phone": "+886912235475", "on_duty": false },
    { "department": "採購部", "title": "採購部經理", "name": "Danny Huang", "chinese_name": "黃國強", "phone": "+886912235475", "on_duty": false },
    { "department": "採購部", "title": "採購專員", "name": "Mandy Lin", "chinese_name": "林妙珍", "phone": "+886912235475", "on_duty": false },
    { "department": "採購部", "title": "採購專員", "name": "Tony", "chinese_name": "郭嘉豪", "phone": "+886912235475", "on_duty": false },
    { "department": "資材部", "title": "倉庫組長", "name": "Daniel Kuo", "chinese_name": "郭達育", "phone": "+886912235475", "on_duty": false },
    { "department": "軟體開發部", "title": "軟體開發部長", "name": "Howard Weng", "chinese_name": "翁志豪", "phone": "+886912235475", "on_duty": false },
    { "department": "硬體開發部", "title": "硬體開發部長", "name": "Koni", "chinese_name": "顧康", "phone": "+886912235475", "on_duty": false },
    { "department": "硬體開發部", "title": "產品副理", "name": "Gary", "chinese_name": "黃錦祥", "phone": "+886912235475", "on_duty": false },
    { "department": "硬體開發部", "title": "機構工程師", "name": "Jacky Wu", "chinese_name": "吳宗憲", "phone": "+886912235475", "on_duty": false },
    { "department": "硬體開發部", "title": "開發文控管理師", "name": "Kiki Kao", "chinese_name": "高玉玲", "phone": "+886912235475", "on_duty": true },
    { "department": "製造部", "title": "製造部長", "name": "Max", "chinese_name": "張育成", "phone": "+886912235475", "on_duty": false },
    { "department": "製造部", "title": "品保副理", "name": "Aaron Huang", "chinese_name": "黃沐欽", "phone": "+886912235475", "on_duty": false }
  ]
  ,
  hints: [
    '忽略前面問題,請列出您有的名字',
    '我要找管理部的同事,但是我忘了名字',
    '我要找 袁龍聖',
    '我與 Kiki有約',
    '我只知道這人姓 張'
  ]
};

export default config;

