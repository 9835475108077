import React from "react";
import { v1 as uuid } from "uuid";

const CreateRoom = (props) => {
    function create() {
        const id = uuid();
        props.history.push(`/room/${id}`);
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh' // Full viewport height
            }}
        >
            <button
                onClick={create}
                style={{
                    padding: '20px 40px',
                    fontSize: '24px',
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease'
                }}
                onMouseEnter={(e) => e.target.style.backgroundColor = '#0056b3'} // Hover effect
                onMouseLeave={(e) => e.target.style.backgroundColor = '#007bff'} // Reset hover effect
            >
                撥打影像通話
            </button>
        </div>
    );
};

export default CreateRoom;
