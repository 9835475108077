// CustomLayout.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap'; // Import Bootstrap components

function CustomLayout({ children }) {
    const location = useLocation(); // Get the current location

    // Define styles for the container and the navigation bar
    const containerStyle = {
        padding: '20px',
        backgroundColor: '#f8f9fa',
        borderBottom: '1px solid #ddd',
    };

    const navBarStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: '#40C1AC', // Soft teal background color
        borderRadius: '10px',
        padding: '10px 0',
    };

    // Define styles for individual buttons and the active button
    const buttonStyle = {
        textDecoration: 'none',
        padding: '10px 20px',
        borderRadius: '10px',
        color: '#fff',
        backgroundColor: '#40C1AC', // Same color as nav bar
        fontSize: '1rem',
        fontWeight: 'bold',
        border: 'none',
        transition: 'background-color 0.3s ease',
    };

    const activeButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#ffffff', // White for active tab
        color: '#40C1AC', // Teal text color for active tab
    };

    return (
        <Container fluid>
            {/* Custom header with a specific size */}
            <div style={containerStyle}>
                {/* Tab-style navigation bar */}
                <div style={navBarStyle}>
                    {/* <Link
                        to="/"
                        style={location.pathname === '/' ? activeButtonStyle : buttonStyle}
                    >
                        Home
                    </Link> */}
                    <Link
                        to="/construction"
                        style={location.pathname === '/construction' ? activeButtonStyle : buttonStyle}
                    >
                        工地相機上傳
                    </Link>
                    <Link
                        to="/constructionsearch"
                        style={location.pathname === '/constructionsearch' ? activeButtonStyle : buttonStyle}
                    >
                        多工地管理者分析看版
                    </Link>
                    <Link
                        to="/hotel"
                        style={location.pathname === '/hotel' ? activeButtonStyle : buttonStyle}
                    >
                        飯店相機上傳
                    </Link>
                    <Link
                        to="/hotelsearch"
                        style={location.pathname === '/hotelsearch' ? activeButtonStyle : buttonStyle}
                    >
                        多飯店管理者分析看版
                    </Link>
                </div>
            </div>

            {/* Page content */}
            <div style={{ padding: '20px' }}>
                {children}
            </div>
        </Container>
    );
}

export default CustomLayout;
